<template>
    <div>
        <b-row>
            <b-col xl="12" class="text-center">
                <div v-if="datosCapacitacion.documento === '' || datosCapacitacion.documento === null || datosCapacitacion.documento === undefined" class="text-center">
                        <div class="alert alert-primary d-flex align-items-center p-4" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            <div class="mx-3">
                                No se encontró el documento de la Capacitación
                            </div>
                        </div>
                </div>
                <div class="position-relative" v-else>
                        <div v-if="isError">
                            <div
                                >error al cargar el canvas
                                
                            </div>
                            <div class="alert alert-danger" role="alert">
                                <h4 class="alert-heading">>Error al cargar el canvas</h4>
                                <p class="mb-0">{{ messageError }}</p>
                                <hr>
                                <p class="mb-0">Disculpa por las molestias!</p>
                            </div>
                        </div>
                        <div v-else-if="isLoading">
                            <div class="py-5">
                                <div class="py-4">
                                <div class="loader">Cargando
                                    <span></span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div ref="canvasContainer" class="canvas-container position-relative">
                                <canvas ref="pdfCanvas"></canvas>
                                <button 
                                    @click      = "changePage(-1)" 
                                    class       = "btn position-absolute top-0 bottom-0" 
                                    style       ="left:0; height:95%; margin:auto"
                                    :disabled   = "pageNum <= 1"
                                >
                                    
                                    <span class="btn btn-outline-dark rounded-circle fw-bold text-primary p-3" style="font-size: 26px;"><<</span> 
                                </button>
                                <button 
                                    @click      = "changePage(1)" 
                                    class       = "btn position-absolute top-0 bottom-0" 
                                    style       ="right:0; height:95%; margin:auto"
                                    :disabled   = "pageNum >= numPages"
                                >
                                    <span class="btn btn-outline-dark rounded-circle fw-bold text-primary p-3" style="font-size: 26px;">>></span> 
                                </button>
                            </div>
                            <div class = "w-100 d-flex justify-content-center align-items-center mt-4">
                                <button 
                                    @click  = "toggleFullScreen" 
                                    class   = "btn btn-primary"
                                >
                                    {{ isFullScreen ? "Salir de pantalla completa" : "Pantalla completa" }}
                                </button>
                            </div>
                        </div>
                </div>
            </b-col>
        </b-row>
    </div>
    </template>
    
    <script>
    import axios from "axios";
    import CONSTANTES from "@/Constantes.js";
    import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.worker.min.js`;
  
    
    export default {
        name        : "CanvasCapacitaciones",
        data() {
            return {
                isPDFLoading    : false,
                isEditing       : false,
                isFullScreen    : false,
                pdfDocument     : null,
                isError         : false,
                messageError    : '',
                isLoading       : false,
                pages           : [], 
                pageNum         : 1,
                numPages        : 0,
            };
        },
        props   : {
            isTrabajador: {
                type: Boolean,
                default: false,
            },
            datosCapacitacion: {
                type: Object,
                required: true,
                default: () => ({
                    idCapacitacion          : "",
                    idPersonaCapacitacion   : "",
                    nombre                  : "",
                    descripcion             : "",
                    urlArchivo              : "",
                    urlPortada              : "",
                    nombre_tipo             : "",
                    proceso                 : "",
                    documento               : null,
                }),
            },
        },
        watch: {
            'datosCapacitacion.documento': {
                immediate: true,
                handler(newVal) {
                    if (!newVal) {
                        this.isError = false;
                        this.isLoading = false;
                    } else {
                        this.loadPDF();
                    }
                },
            },
        },
        methods: {
            toggleFullScreen() {
                let me = this;
                const canvasContainer = this.$refs.canvasContainer;
                if (me.isFullScreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                } else {
                    if (canvasContainer.requestFullscreen) {
                        canvasContainer.requestFullscreen();
                    }
                }
            },
    
            async loadPDF() {
                try {
                    if (this.isPDFLoading) return; // Si ya está cargando, no hacer nada
                    this.isPDFLoading = true;

                    this.pdfDocument = null;
                    this.pageNum = 1;
                    this.numPages = 0;
                    this.isError = false;
                    this.isLoading = false;
                
                    if(this.datosCapacitacion.documento !== null && this.datosCapacitacion.documento !== undefined){
                        this.isLoading      = true;
                        const loadingTask   = getDocument(this.datosCapacitacion.documento);
                        this.pdfDocument    = await loadingTask.promise;
                        this.numPages       = this.pdfDocument.numPages;
                        this.pageNum        = 1; // Inicializa en la primera página
                        await this.renderPage(this.pageNum);
                    }
                } catch (error) {
                    this.isError        = true;
                    this.messageError   = error;
                }
            }, 
            async renderPage(pageNum) {
                try {
                    if (this.renderTask) {
                        this.renderTask.cancel();
                        this.renderTask = null;
                    }
                    this.isLoading   = false;
                    await this.$nextTick();
                    const page      = await this.pdfDocument.getPage(pageNum);
                    const scale     = 1.5; // Escala para el viewport
                    const viewport  = page.getViewport({ scale });
                    const canvas    = this.$refs.pdfCanvas;
                    if(canvas){
                        const context   = canvas.getContext('2d');
                        canvas.height   = viewport.height;
                        canvas.width    = viewport.width;
    
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };
                        this.renderTask = page.render(renderContext);
                        this.renderTask = null;
                    }
                } catch (error) {
                    if (error?.name === "RenderingCancelledException") {
     
                    }
                } finally {
                    await this.$nextTick();
                    
                }
            }, 
    
            changePage(direction) {
                if (this.isRendering) return;
                this.pageNum += direction;
                if (this.pageNum < 1) this.pageNum = 1;
                if (this.pageNum > this.numPages) this.pageNum = this.numPages;
                this.isRendering = true;
                this.renderPage(this.pageNum);
                this.isRendering = false;

                if(this.isTrabajador){
                    if (parseInt(this.datosCapacitacion.proceso) === 1 && this.pageNum > 0) {
                        this.ActualizarEstadoCapacitacion(2); // Actualiza a estado 'comenzado'
                    }
                    if (this.datosCapacitacion.proceso === 2 && this.pageNum === this.numPages) {
                        this.ActualizarEstadoCapacitacion(3); // Actualiza a estado 'completado'
                    }
                }
            },
            handleKeyDown(event) {
                let me = this;
                if (me.isRendering) return; 
                if (event.key === "ArrowRight") {
                    me.changePage(1); // Siguiente página
                } else if (event.key === "ArrowLeft") {
                    me.changePage(-1); // Página anterior
                }
            },

            isEmpezado: function (val) {
                let me = this
                if (me.datosCapacitacion.proceso == 1) {
                    if (me.isEmpezado == 1) {
                        me.ActualizarEstadoCapacitacion(2)
                    }
                }
            },
            isUltimo: function (val) {
                let me = this
                if (me.datosCapacitacion.proceso == 2) {
                    if (me.isUltimo == 1) {
                        me.ActualizarEstadoCapacitacion(3)
                    }
                }
            },

            ActualizarEstadoCapacitacion(estado) {
                let me = this
                axios.post( CONSTANTES.URL_RUTA_SERVICIOS + "trabajador/actualizar-capacitacion-proceso", {
                    id_persona_capacitacion: me.datosCapacitacion.idPersonaCapacitacion,
                    proceso: estado,
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    },
                }).then(function (response) {
                    if (response) {
                        me.$emit('estado-actualizado', estado);
                    }
                }).catch(function (error) {
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: 'Error, algo salió mal.'
                        });
                });
            },
        },
        
        mounted() {
            let me = this;
            if(this.datosCapacitacion.documento){ 
                me.loadPDF();
                document.addEventListener("keydown", this.handleKeyDown);  
                document.addEventListener("fullscreenchange", () => {
                    me.isFullScreen = !!document.fullscreenElement;
                });
            }
        }
    };
    </script>
    
    <style scoped>
    canvas {
        width: 100%;
        height: auto; 
    }
    </style>
    
    