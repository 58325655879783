<!--capacitaciones disponibles
seleccionar capacitacion
Ver estadistcias
Subir material-->
<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12">
                <template v-if="datosFiliacion.dias_restantes <= 5 ">
                    <b-alert v-if="datosFiliacion.fecha_fin > datosFiliacion.fecha_actual" show dismissible variant="default">Hola, su plan actual culminará en {{datosFiliacion.dias_restantes}} días.</b-alert>
                    <b-alert v-if="datosFiliacion.fecha_fin <= datosFiliacion.fecha_actual" show dismissible variant="danger">Hola, su plan actual culminó el dia {{datosFiliacion.fecha_fin}}, tiene hasta el día {{datosFiliacion.fecha_plazo}} para renovar.</b-alert>
                    <!--   <b-alert v-if="DatosFiliacion.Fecha_fin = DatosFiliacion.Fecha_actual" show dismissible variant="warning">Hola {{newPersona.usuario}}, su afiliación terminará el dia de hoy.</b-alert>-->
                </template>
            </b-col>
            <b-col xl="4" md="6">
                <stats-card title="Trabajadores" type="gradient-red" :sub-title="datosPanel.cant_trabajadores" icon="person-circle" class="mb-4">
                </stats-card>
            </b-col>
            <b-col xl="4" md="6">
                <stats-card title="Capacitaciones" type="gradient-orange" :sub-title="datosPanel.cant_capacitaciones" icon="puzzle-fill" class="mb-4">
                </stats-card>
            </b-col>
            <b-col xl="4" md="6">
                <stats-card title="Evaluaciones" type="gradient-green" :sub-title="datosPanel.cant_evaluaciones" icon="file-medical-fill" class="mb-4">
                </stats-card>
            </b-col>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12">
                    <card header-classes="bg-transparent">
                        <template v-slot:header>
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h3 class="mb-0">Capacitaciones</h3>
                                </b-col>
                            </b-row>
                        </template>
                        <b-row>
                            <b-col lg="4">
                                <base-input v-if="!cargaCapacitacionesCliente">
                                    <b-input-group class="text-right" size="xl">
                                        <b-input-group-prepend is-text>
                                            <b-icon icon="search"></b-icon>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filtro" id="filterInput" placeholder="Buscar..." autocomplete="off"></b-form-input>
                                        <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                    </b-input-group>
                                </base-input>
                            </b-col>
                            <b-col lg="4">

                            </b-col>
                            <b-col lg="4">
                                <base-input v-if="!cargaCapacitacionesCliente">
                                    <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                </base-input>
                            </b-col>
                            <b-col lg="12">
                                <b-list-group flush class="list my--3 ">
                                    <b-list-group-item action v-for="item of listaCapacitacionesSorter" :key="item.image" class="list-group-item  ">
                                        <router-link :to="{name:'gestión capacitación',params: {id: item.id_cliente_capacitacion,nombreCapacitacion: item.nombre}}" class="text-light">
                                            <b-row align-v="center">
                                                <b-col md="auto" class="movil-div">
                                                    <b-img class="movil-img img-responsive img-fluid" alt="Image" center rounded width="100px" :src="item.portada" />
                                                </b-col>
                                               <b-col class="auto">
                                                    <h4 class="mb-1 mt-2">
                                                        {{item.nombre}}
                                                    </h4>
                                                    <h4>
                                                        <b-badge class="defaultx">{{item.nombre_tipo}}</b-badge>
                                                    </h4>
                                                </b-col>
                                                <!-- <b-col class="ml--2 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 class="mb-0 text-sm">{{item.nombre}}</h4>
          </div>
          <div class="text-right text-muted">
            <small>2 hrs ago</small>
          </div>
        </div>
        <p class="text-sm mb-0"><b-badge class="defaultx">{{item.nombre_tipo}}</b-badge></p>
      </b-col> -->

                                                <!--      <b-col md="auto text-left" class="mb-1 mt-1">
                                                    <b-button type="button" size="sm" variant="default">Seleccionar
                                                        <b-icon icon=" arrow-right" aria-label="Help">
                                                        </b-icon>
                                                    </b-button>
                                                </b-col>-->
                                            </b-row>
                                        </router-link>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                        </b-row>
                        <template v-slot:footer>
                            <b-row v-if="!cargaCapacitacionesCliente">
                                <b-col lg="12">
                                    <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-col>
            </b-row>
        </div>
    </b-container>
    <!--End container-->
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import CryptoJS from 'crypto-js';

export default {
    name: "capacitaciones",
    components: {

    },
    data() {
        return {
            cargaCapacitacionesCliente: true,
            listaCapacitacionesCliente: [],

            datosCliente: {
                idCliente: "",
            },
            datosPanel: {
                cant_capacitaciones: "",
                cant_evaluaciones: "",
                cant_trabajadores: "",
            },
            datosFiliacion: {
                fecha_fin: "", //fin del plan
                fecha_inicio: "", //inicio del plan
                fecha_actual: "", //fecha de hoy
                fecha_plazo: "", //fecha restante (fecha final + 5 dias de chance)
                dias_restantes: "", //cant de dias restantes
            },

            paginaActual: 1,
            porPagina: 5,
            paginaOpciones: [5, 10, 20, {
                value: 99999,
                text: "Mostrar todo"
            }],
            filtro: "",

        };
    },

    methods: {
        ContarDatosPanel() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "responsable/contar-datos-panel", {
                        params: {
                            idCliente: me.datosCliente.idCliente,
                        }
                    }
                )
                .then(function (response) {
                    if (response) {
                        me.datosPanel.cant_capacitaciones = String(response.data[0].cant_capacitaciones)
                        //  me.datosPanel.cant_evaluaciones = String(response.data[0].cant_evaluaciones)
                        me.datosPanel.cant_trabajadores = String(response.data[0].cant_trabajadores)
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        ContarCantEval() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "responsable/listar-evaluacion-trabajadores", {
                        params: {
                            idCliente: me.datosCliente.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.datosPanel.cant_evaluaciones = String(response.data.length)

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarCapacitacionesCliente() {
            let me = this;
            me.cargaCapacitacionesCliente = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "responsable/listar-capacitaciones-cliente", {
                        params: {
                            idCliente: me.datosCliente.idCliente,
                        }
                    }
                )
                .then(function (response) {
                    if (response) {
                        me.listaCapacitacionesCliente = response.data
                        me.cargaCapacitacionesCliente = false
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        decryptData(encryptedData) {
            try{
                const SECRET_KEY    = process.env.VUE_APP_SECRET_KEY;
                const bytes         = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
                const decrypt       = bytes.toString(CryptoJS.enc.Utf8);
                return decrypt;
            }catch(error){
                console.log(error);
            }
        },

    },
    computed: {
        listaFiltrados() {
            return this.listaCapacitacionesCliente.filter(itemx => {
                return itemx.nombre.toLowerCase().indexOf(this.filtro.toLowerCase().trim()) > -1
            })
        },
        listaCapacitacionesSorter() {
            const items = this.listaFiltrados
            return items.slice(
                (this.paginaActual - 1) * this.porPagina,
                this.paginaActual * this.porPagina
            )
        },
        filasTotales() {
            return this.listaFiltrados.length
        }
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            const decrypt   = me.decryptData(localStorage.usuario);
            const lsUsuario = JSON.parse(decrypt); 
            me.datosCliente.idCliente        = lsUsuario.nIdCliente
            me.datosFiliacion.fecha_fin      = lsUsuario.Fechafin;
            me.datosFiliacion.fecha_inicio   = lsUsuario.FechaInicio;
            me.datosFiliacion.fecha_actual   = lsUsuario.FechaActual;
            me.datosFiliacion.fecha_plazo    = lsUsuario.FechaPlazo;
            me.datosFiliacion.dias_restantes = lsUsuario.DiasRestantes;

            me.ListarCapacitacionesCliente();
            me.ContarDatosPanel();
            me.ContarCantEval()

        }

    }
};
</script>
