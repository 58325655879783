import Swal from 'sweetalert2';
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

const sweetMessage = (icon, title, timer) => {
    return Swal.fire({
        toast: true,
        showConfirmButton: false,
        position: 'bottom-right',
        timer: timer,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
        icon: icon,
        title: title,
    });
};

const swat = (icon, title) => {
    return Swal.fire({
        toast: true,
        showConfirmButton: false,
        position: 'bottom-right',
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        icon: icon,
        title: title,
    })
}


const sweetQuery = async (titulo, html, icon ,url, formData, method, validations = []) => {
    return new Promise((resolve,reject) => {
        let timerInterval;
        Swal.fire({
            title               : titulo,
            html                : html,
            icon                : icon,
            showCancelButton    : true,
            confirmButtonColor  : '#198754',
            cancelButtonColor   : '#dc3545',
            confirmButtonText   : 'Aceptar',
            cancelButtonText    : 'Cancelar',
            didOpen: () => {
                const fileInput     = document.getElementById("filepreview");
                const previewImage  = document.getElementById("previewImage");
                if(fileInput && previewImage){
                    fileInput.addEventListener("change", (event) => {
                        const file = event.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            previewImage.src = e.target.result;
                            previewImage.style.display = "block";
                          };
                          reader.readAsDataURL(file);
                        }
                    });
                }
            },
            preConfirm: async () => { 
                try {
                    const swal2  = document.querySelector('.swal2-html-container');
                    const inputs = Array.from(swal2.querySelectorAll('input, select, textarea'));
  
                    if (validations.length > 0) {
                        for (let validation of validations) {
                            const input = inputs.find((el) => el.name === validation.name);
                            if (input) {
                                if (input.type === 'file') {
                                    const file = input.files[0];
                                    if (!file) {
                                        Swal.showValidationMessage(validation.mensaje);
                                        return false;
                                    }
                                    const fileExtension = file.name.split('.').pop().toLowerCase();
                                    if (!validation.extensions.includes(fileExtension)) {
                                        Swal.showValidationMessage(
                                            `El archivo debe ser de tipo: ${validation.extensions.join(', ')}`
                                        );
                                        return false;
                                    }
                                } else if (input.value.trim() === '') {
                                    Swal.showValidationMessage(validation.mensaje);
                                    return false;
                                }
                            }
                        }
                    }
                    if(inputs.length > 0){
                        for (const input of inputs) {
                            if (input.type === 'file') {
                                const file = input.files[0];
                                if (file) {
                                    formData.append(input.name, file);
                                }
                            } else {
                                formData.append(input.name, input.value);
                            }
                        }
                    }
                    Swal.showLoading();
                    let response;
                    axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${localStorage.token}`;
                    switch (method.toLowerCase()) {
                        case 'post':
                            response = await axios.post(
                                CONSTANTES.URL_RUTA_SERVICIOS + url,
                                formData, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            )
                            break;
                        case 'put':
                            response = await axios.post(url, formData);
                            break;
                        case 'delete':
                            response = await axios.delete(url, formData);
                            break;
                        default:
                            throw new Error('Método HTTP no soportado');
                    }
                    
                    //const response = await axios.put(url, Data);
                    resolve(response.data);

                    if('original' in response.data){
                        sweetMessage('info',`Error: ${response.data.original.error}`, 2500);
                    }else if('success' in response.data && !response.data.success){
                        sweetMessage('info',`Error: ${response.data.error}`, 2500);
                    } 
                } catch (error) {
                    console.log(error); 
                    if (error.response) {
                        const errorData = error.response.data || {};
                        const errorMessage = errorData.error || errorData.message || 'Error desconocido'; 
                        sweetMessage('error',`Error: ${error.response.status} - ${errorMessage}`, 2500);
                    } else if (error.request) {
                        sweetMessage('error','Error: No response received from server.', 2500);
                    } else {
                        sweetMessage('error',`Error: ${error.message}`, 2500);
                    }
                    reject(error);
                }
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {
            if (result.dismiss) {
                resolve(result); 
            }
        }); 
    });
};


export { sweetQuery,sweetMessage, swat};