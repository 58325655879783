<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <b-row>
            <b-col xl="12">
                <card header-classes="bg-transparent ">
                    <template v-slot:header>
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Evaluaciones</h3>
                            </b-col>
                        </b-row>
                    </template>

                    <b-row>
                        <b-col lg="4">
                            <base-input v-if="!cargarEvaluacion">
                                <b-input-group class="text-right" size="xl">
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="search"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                              
                           <!--       <v-select :options="options"  label="country" >
                                  	<div slot="no-options">Sin resultados</div>
                                  </v-select>-->

                        </b-col>
                        <b-col lg="4">
                            <base-input v-if="!cargarEvaluacion">
                                <b-form-select size="md" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                            </base-input>
                        </b-col>

                        <b-col lg="12">
                            <b-table show-empty small responsive outlined :items="listaEvaluaciones" :fields="camposEvaluacion" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(fecha_creacion)="data">
                                        {{ data.item.fecha_creacion ? DateTime.fromISO(data.item.fecha_creacion, 'yyyy-MM-dd HH:mm:ss').setLocale('es').toFormat('dd-LL-yyyy') : '' }}
                                </template>
                                <template v-slot:cell(opciones)="param">
                                    <base-button v-if="param.item.estado == 2" icon size="sm" type="primary" @click="HabilitarEvaluacion(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="reply-fill" aria-label="Help"></b-icon>
                                        </span>
                                        Habilitar reintento
                                    </base-button>

                                    <base-button icon size="sm" type="default" @click="param.toggleDetails">
                                        <span class="btn-inner--icon">
                                            <b-icon :icon="param.detailsShowing ? 'eye-slash-fill' : 'eye-fill'" aria-label=" Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">{{ param.detailsShowing ? 'Ocultar' : 'Mostrar'}} detalles</span>
                                    </base-button>
                                </template>

                                <template v-slot:row-details="row">
                                    <b-row class="mb-2">
                                        <b-col xl="6"><b>Total de Preguntas: </b>{{ row.item.n_preguntas }}</b-col>
                                        <b-col xl="6"><b>% Aciertos: </b>{{ ((parseInt(row.item.n_correctas)*100)/row.item.n_preguntas).toFixed(2) }}%</b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col xl="6"><b>Total correctas: </b>{{ row.item.n_correctas }}</b-col>
                                        <b-col xl="6"><b>Total incorrectas: </b>{{ row.item.n_incorrectas }}</b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col xl="6"><b>N° de intentos: </b>{{ row.item.n_intento }}</b-col>
                                        <b-col xl="6"><b>Fecha de ultimo intento: </b>{{ row.item.fecha_modificacion ? DateTime.fromISO(row.item.fecha_modificacion, 'yyyy-MM-dd HH:mm:ss').setLocale('es').toFormat('d LLLL yyyy, hh:mm a') : '' }}</b-col>
                                    </b-row>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row v-if="!cargarEvaluacion">
                        <b-col lg="12 mt-3">
                            <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </b-container>
    <!--End container-->
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import 'vue-select/dist/vue-select.css';
import CryptoJS from 'crypto-js';
import { DateTime } from 'luxon';

export default {
    components: {},
    data() {
        return {
            idCliente: "",

            options: [{code: 'CA', country: 'Canada'}],


            cargarEvaluacion: false,
            listaEvaluaciones: [],
            camposEvaluacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "NombreCompleto",
                    label: "Trabajador",
                    class: "text-center",

                },

                {
                    key: "nombre",
                    label: "Evaluación",
                    class: "text-center",

                },
                {
                    key: "nombre_cap",
                    label: "Capacitación",
                    class: "text-center",
                },

                {
                    key: "fecha_creacion",
                    label: "Fecha de registro",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    label: "opciones",
                    class: "text-center"
                },
            ],
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],

            filter: null,
            filterOn: [],
            DateTime,
        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        HabilitarEvaluacion(param) {
            let me = this
            let Nombre = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    'Esta seguro que habilitar el sustitutorio de la evaluación  "' +
                    Nombre +
                    '" ? ', {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "responsable/habilitar-evaluacion", {
                                    id_persona_evaluacion: param.item.id_persona_evaluacion,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarEvaluacionesTrabajadores();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },

        ListarEvaluacionesTrabajadores() {
            let me = this;
            me.cargarEvaluacion = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "responsable/listar-evaluacion-trabajadores", {
                        params: {
                            idCliente: me.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.listaEvaluaciones = response.data
                    me.filasTotales = me.listaEvaluaciones.length
                    me.cargarEvaluacion = false
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                    me.cargarEvaluacion = true
                });
        },
        decryptData(encryptedData) {
            try{
                const SECRET_KEY    = process.env.VUE_APP_SECRET_KEY;
                const bytes         = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
                const decrypt       = bytes.toString(CryptoJS.enc.Utf8);
                return decrypt;
            }catch(error){
                console.log(error);
            }
        },
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            const decrypt   = this.decryptData(localStorage.usuario);
            const lsUsuario = JSON.parse(decrypt); 
            me.idCliente    = lsUsuario.nIdCliente
            me.ListarEvaluacionesTrabajadores();
        }
    }
};
</script>

<style >
.vs__dropdown-toggle {
padding: 6px 9px 6px !important;
}
.vs__clear {
    visibility: hidden;
}
</style>
