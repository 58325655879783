<!--
*Ver cabecera
*Ver estadisticas
*Asignar Trabajadores
*Subir Archivos
-->
<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12" class="order-xl-2 ">
                <b-overlay :show="cargarInfoCapacitacion" rounded="lg" opacity="0.5">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <b-card no-body class="card-profile" alt="Image placeholder" img-top>
                        <b-card-body>
                            <b-row>
                                <b-col lg="5 text-center" style="align-self: center;">
                                    <template>
                                        <div>
                                            <b-img class=" img-responsive img-fluid" :alt="datosCapacitacion.nombreCapacitacion" center rounded width="400px" height="240px" :src="datosCapacitacion.urlPortada" />
                                            <!-- <b-img-lazy width="500px" height="230px" center fluidGrow blank="true" blankColor="#bbb" :src="datosCapacitacion.urlPortada" rounded :alt="datosCapacitacion.nombreCapacitacion"></b-img-lazy> -->
                                        </div>
                                    </template>
                                </b-col>
                                <b-col lg="7">
                                    <div class="text-left">
                                        <div class="h4 mt-4">
                                            {{datosCapacitacion.nombre}}
                                        </div>
                                        <div class="h5 font-weight-300" align="justify">
                                            {{datosCapacitacion.descripcion}}
                                        </div>
                                        <hr class="my-3">
                                        <div class="h4 font-weight-300">
                                            <b-badge class="defaultx">{{ datosCapacitacion.nombre_tipo }}</b-badge>
                                        </div>
                                        <div class="h5 font-weight-300">
                                            <!--     <base-button icon size="sm" type="default" v-b-modal.modal-vista-previa>
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text"> Vista Previa</span>
                                            </base-button>-->
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-overlay>
            </b-col>
        </b-row>
    </base-header>
    <!--End head-->
    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12 ">
                    <b-overlay :show="cargarEstadisticas" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent" class="mt-4">
                            <template v-slot:header>
                                <b-row align-v="center" slot="header">
                                    <b-col lg="12">
                                        <h3 class="mb-0">Estadísticas de la capacitación</h3>
                                    </b-col>
                                    <b-col class="text-right">
                                        <!--   <span class="btn btn-sm btn-primary">
                                            <b-icon icon="pie-chart-fill" aria-hidden="true"></b-icon> Ver detalle
                                        </span>-->
                                    </b-col>
                                </b-row>
                            </template>
                            <b-row>
                                <b-col xl="6" lg="6" sm="12" class="mb-3">
                                    <h3 class="text-muted text-center mb-3">Cantidad de trabajadores asignados en la capacitacion por estados</h3>
                                    <pie-chart v-if="cargaPieChart" :data="chartData" :options="options"></pie-chart>
                                </b-col>
                                <b-col xl="6" lg="6" sm="12">
                                    <h3 class="text-muted text-center mb-3">Cantidad de evaluaciones completadas por los trabajadores en el {{anio}}</h3>
                                    <line-chart :data="chartDataBar" :options="optionsBar" v-if="cargaBarChart == true"></line-chart>
                                </b-col>
                            </b-row>
                        </card>
                    </b-overlay>
                </b-col>
                <!--      <b-col xl="12 ">
                <card header-classes="bg-transparent" class="mt-4">
                    <b-row align-v="center" slot="header">
                        <b-col>
                            <h5 class="h3 mb-0"> Sesiones Grabadas</h5>
                        </b-col>
                        <b-col class="text-right">
                            <span class="btn btn-sm btn-primary">
                                <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar Sesión
                            </span>
                        </b-col>
                    </b-row>
                </card>
            </b-col>-->
                <b-col xl="12 ">
                    <b-overlay :show="cargarTrabajadores" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent" class="mt-4">
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h5 class="h3 mb-0"> Trabajadores asignados</h5>
                                </b-col>
                                <b-col class="text-right">
                                    <span class="btn btn-sm btn-primary" v-b-modal.modal-asignar-trabajador>
                                        <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Asignar
                                    </span>
                                </b-col>
                            </b-row>
                            <b-table sort-icon-left show-empty small responsive outlined :items="listaTrabajadores" :fields="camposTrabajadores" :current-page="paginaActualTra" :per-page="porPaginaTra" empty-text="Aún no hay trabajadores en esta sección.">
                                <template v-slot:cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(estado)="datax">
                                    <b class="text-default" v-if="datax.item.proceso == 1">PENDIENTE</b>
                                    <b class="text-sucess" v-if="datax.item.proceso == 2">EN CURSO</b>
                                    <b class="text-danger" v-if="datax.item.proceso == 3">FINALIZADO</b>

                                </template>
                            </b-table>
                            <template v-slot:footer>
                                <b-row v-if="!cargarTrabajadores">
                                    <b-col lg="12">
                                        <b-pagination size="sm" style="justify-content:flex-end;" v-model="paginaActualTra" :total-rows="filasTotalesTra" :per-page="porPaginaTra" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>
                <b-col xl="12">
                    <b-overlay :show="cargarEvaluacion" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent" class="mt-4">
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h5 class="h3 mb-0"> Evaluaciones</h5>
                                </b-col>
                                <!--   <b-col class="text-right">
                            <span class="btn btn-sm btn-primary" v-b-modal.modal-asignar-evaluacion>
                                <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar Evaluación
                            </span>
                        </b-col>-->
                            </b-row>
                            <b-table sort-icon-left show-empty small responsive outlined :items="listaEvaluaciones" :fields="camposEvaluacion" :current-page="paginaActualEva" :per-page="porPaginaEva" empty-text="Aún no hay evaluaciones en esta sección.">
                                <template v-slot:cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(opciones)="param">
                                    <base-button icon size="sm" type="default" @click="verInformacionEvaluacion(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Ver información</span>
                                    </base-button>
                                </template>
                            </b-table>
                            <template v-slot:footer>
                                <b-row v-if="!cargarEvaluacion">
                                    <b-col lg="12">
                                        <b-pagination size="sm" style="justify-content:flex-end;" v-model="paginaActualEva" :total-rows="filasTotalesEva" :per-page="porPaginaEva" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>

                <b-col xl="12 ">
                    <b-overlay :show="cargandoArchivos" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent" class="mt-4">
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h5 class="h3 mb-0">Archivos</h5>
                                </b-col>
                            </b-row>
                            <base-input input-classes="form-control-sm">
                                <b-form-file v-model="ArchivoCapacitacion" class="form-control-sm" size="sm" placeholder="Elija un archivo o arrástrelo aquí..." :accept="accept.toString()" @change="procesarArchivo" ref="input-archivos" browse-text="Subir"></b-form-file>
                            </base-input>
                            <b-alert dismissible :show="emitirAlerta" variant="danger">{{mensajeAlerta}}</b-alert>
                            <b-table sort-icon-left show-empty small responsive outlined :items="listaArchivos" :fields="campoArchivos" :current-page="paginaActualArc" :per-page="porPaginaArc" empty-text="Aún no hay archivos en esta sección." :busy="cargandoArchivos">
                                <template v-slot:cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(opciones)="param">
                                    <base-button icon size="sm" type="success" @click="descargarArchivo(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="cloud-download" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Descargar </span>
                                    </base-button>
                                    <template v-if="param.item.idCliente !=0">
                                        <base-button icon size="sm" type="danger" @click="eliminarArchivo(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Eliminar </span>
                                        </base-button>
                                    </template>
                                </template>
                                <template v-slot:table-busy>
                                    <div style="  color: #525f7f !important; " class="text-center text-secondary my-2">
                                        <b-spinner class="align-middle" small></b-spinner>
                                        <strong> Cargando ...</strong>
                                    </div>
                                </template>
                            </b-table>
                            <template v-slot:footer>
                                <b-row v-if="!cargandoArchivos">
                                    <b-col lg="12">
                                        <b-pagination size="sm" style="justify-content:flex-end;" v-model="paginaActualArc" :total-rows="filasTotalesArc" :per-page="porPaginaArc" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
        <!--End tables-->
    </b-container>

    <b-modal id="modal-asignar-evaluacion" ref="modal-asignar-evaluacion" title="Asignar nueva evaluacion" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalEvaluaciones">
        <b-row>
            <b-col xl="12">
                <b-table :items="listaEvaluacionesModal" class="mt-2" small outlined selectable select-mode="multi" @row-selected="onRowSelected" :fields="camposEvaluacionSmall" responsive show-empty empty-text="No se encontraron evaluaciones">
                    <template #cell(index)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <b-check class="text-center" size="sm" checked="true"></b-check>
                            <!--  <span aria-hidden="true">&check;</span>-->
                        </template>
                        <template v-else>
                            <b-check size="sm" checked="false">
                            </b-check>
                            <!--        <span aria-hidden="true">&nbsp;</span>-->
                        </template>
                    </template>
                    <template v-slot:cell(estado)="data">
                        <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                        <b class="text-success" v-else>ACTIVO</b>
                    </template>
                </b-table>
            </b-col>
            <b-col lg="12 text-right">
                <b-button size="sm" variant="success" class="mb-2" @click="AsignarEvaluacion()">
                    Añadir Evaluaciones
                </b-button>
                <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-asignar-evaluacion')">Cerrar</b-button>
            </b-col>
        </b-row>
    </b-modal>

    <b-modal id="modal-asignar-trabajador" ref="modal-asignar-trabajador" title="Asignar a trabajadores" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalTrabajadores">
        <b-row>
            <b-col xl="12">
                <!-- <h4 class="text-muted"> Seleccione a los trabajadores para asignar: </h4> -->
                <b-table sticky-header="400px" :items="listaTrabajadoresModal" class="mt-2" small outlined selectable select-mode="multi" @row-selected="onRowSelectedz" :fields="camposTrabajadoresSmall" responsive show-empty empty-text="No se encontraron trabajadores disponibles.">
                   <template #cell(index)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Seleccionado</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">No seleccionado</span>
                    </template>
                </template>
                 
                </b-table>
            </b-col>
            <b-col lg="12 text-right mt-3">
                <b-button size="sm" variant="success" @click="AsignarTrabajador()">
                    Asignar trabajadores
                </b-button>
                <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-asignar-trabajador')">Cerrar</b-button>
            </b-col>
        </b-row>
    </b-modal>

    <b-modal id="modal-vista-previa" ref="modal-vista-previa" :title="datosCapacitacion.nombre" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalEvaluaciones">
        <b-row>
            <b-col xl="12" class="text-center">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe :src="datosCapacitacion.urlArchivo" width="576" height="420" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
            </b-col>
        </b-row>
    </b-modal>

    <b-modal id="modal-ver-evaluacion" ref="modal-ver-evaluacion" title="Datos de la evaluación" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop>
        <div class="pl-lg-3 pr-lg-3 ">
            <b-row>
                <b-table :items="listaPreguntas" small outlined sticky-header="400px" :fields="camposPreguntas" responsive show-empty empty-text="No se encontraron preguntas">
                    <template v-slot:cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(vermas)="row">
                        <base-button icon size="sm" type="default" @click="row.toggleDetails">
                            <span class="btn-inner--icon">
                                <b-icon :icon="row.detailsShowing ? 'eye-slash-fill' : 'eye-fill'" aria-label=" Help"></b-icon>
                            </span>
                            <span class="btn-inner--text">{{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}} Detalles</span>
                        </base-button>
                    </template>
                    <template v-slot:row-details="row">
                        <b-card class="bg-secondary">
                            <b-row class="mb-2">
                                <b-col sm="2" class="text-sm-right"><b>Opción a): </b></b-col>
                                <b-col>{{ row.item.opcionA }}</b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="2" class="text-sm-right"><b>Opción b): </b></b-col>
                                <b-col>{{ row.item.opcionB }}</b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="2" class="text-sm-right"><b>Opción c): </b></b-col>
                                <b-col>{{ row.item.opcionC }}</b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="3" class="text-sm-right"><b>La respuesta es la opción : {{ row.item.respuesta}})</b></b-col>
                            </b-row>
                        </b-card>
                    </template>
                </b-table>
            </b-row>
        </div>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

// Charts
import LineChart from '@/components/Chartsx/LineChart'
import PieChart from '@/components/Chartsx/PieChart'
import CryptoJS from 'crypto-js';

export default {
    name: "GestionCapacitacion",
    components: {
        LineChart,
        PieChart
    },
    data() {
        return {
            chartData: {
                datasets: [{
                    borderWidth: 1,
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                        'rgba(112, 237,111, 1)',
                        'rgba(255,99,132,1)'
                    ],
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(112, 237, 111, 0.2)',
                        'rgba(255, 99, 132, 0.2)'
                    ],
                    data: []
                }],
                labels: [" Pendiente ", " En curso ", " Finalizado "],
            },
            options: {
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            },
            chartDataBar: {
                labels: [],
                datasets: [{
                    label: 'Evaluaciones',
                    data: [],
                    fill: true,
                    borderColor: '#154B6E',
                    backgroundColor: '#154B6E',
                    borderWidth: 1
                }, ],

            },
            optionsBar: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: true
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            },

            anio: new Date().getFullYear(),

            ArchivoCapacitacion: null,

            idCliente: "",

            cargaPieChart: false,
            cargaBarChart: false,

            cargarInfoCapacitacion: true,
            cargarEstadisticas: true,
            cargandoArchivos: true,
            cargarEvaluacion: true,
            cargarTrabajadores: true,
            cargarPreguntas: false,

            emitirAlerta: false,
            mensajeAlerta: "",
            usuario: "",

            listaArchivos: [],

            listaEvaluaciones: [],
            listaEvaluacionesModal: [],
            listaPreguntas: [],
            listaEvaluacionesModalSeleccionadas: [],

            listaTrabajadores: [],
            listaTrabajadoresModal: [],
            listaTrabajadoresModalSeleccionados: [],

            accept: [".pdf", "application/msword", ".docx", ".ppt", ".pptx", "csv", , ".png", ".jpg", ".jpge", ".xlsx", ".xls"],

            datosCapacitacion: {
                idCapacitacion: "",
                idClienteCapacitacion: "",
                nombre: "",
                descripcion: "",
                urlArchivo: "",
                urlPortada: "",
                nombre_tipo: "",
            },
            campoArchivos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombreArchivo",
                    label: "Nombre",
                    class: "text-center"
                },
                {
                    key: "mimetype",
                    label: "Tipo",
                    class: "text-center"
                },
                {
                    key: "size",
                    label: "Tamaño",
                    class: "text-center"
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    class: "text-center"
                },
            ],
            camposEvaluacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center"
                },
                {
                    key: "n_preguntas",
                    label: "Preguntas disponibles",
                    class: "text-center"
                },

                {
                    key: "opciones",
                    label: "opciones",
                    class: "text-center"
                },
            ],
            camposTrabajadores: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "NombreCompleto",
                    label: "Apellidos y Nombres",
                    class: "text-center "
                },
                {
                    key: "numeroDocumento",
                    label: "Documento de identidad",
                    class: "text-center "
                },

                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center"
                },
                {
                    key: "Email",
                    label: "correo electronico",
                    class: "text-center "
                },
            ],
            camposTrabajadoresSmall: [{
                    key: "index",
                    label: "",
                    class: "text-center pointerx tdx"
                },
                {
                    key: "NombreCompleto",
                    label: "Apellidos y Nombres",
                    class: "text-center pointerx"
                },
                {
                    key: "numeroDocumento",
                    label: "Documento de identidad",
                    class: "text-center pointerx"
                },
                {
                    key: "Email",
                    label: "correo electronico",
                    class: "text-center pointerx"
                },
            ],

            camposEvaluacionSmall: [{
                    key: "index",
                    label: "N°",
                    class: "text-center pointerx tdx"
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center pointerx"
                },
                {
                    key: "fecha_creacion",
                    label: "Fecha",
                    class: "text-center pointerx"
                },
                {
                    key: "n_preguntas",
                    label: "Preguntas disponibles",
                    class: "text-center pointerx"
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center pointerx"
                },
            ],

            camposPreguntas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center ",
                },
                {
                    key: "enunciado",
                    label: "enunciado",
                    class: "text-center",
                },
                {
                    key: "respuesta",
                    label: "Respuesta",
                    class: "text-center ",
                },
                {
                    key: "vermas",
                    label: "Ver más",
                    class: "text-center ",
                }
            ],

            filasTotalesArc: 1,
            paginaActualArc: 1,
            porPaginaArc: 5,

            filasTotalesEva: 1,
            paginaActualEva: 1,
            porPaginaEva: 5,

            filasTotalesTra: 1,
            paginaActualTra: 1,
            porPaginaTra: 5,

        };

    },
    methods: {
        onRowSelectedz(items) {
            let me = this
            items.forEach((a) => {
                a.idCapacitacion = me.datosCapacitacion.idCapacitacion
                a.idClienteCapacitacion = me.datosCapacitacion.idClienteCapacitacion
            })
            me.listaTrabajadoresModalSeleccionados = items
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onRowSelected(items) {
            items.forEach((a) => {
                a.id_capacitacion = me.datosCapacitacion.idCapacitacion
            })
            me.listaEvaluacionesModalSeleccionadas = items
        },
        verInformacionEvaluacion(param) {
            let me = this;
            me.cargarPreguntas = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/listar-preguntas", {
                        params: {
                            idEvaluacion: param.item.id_evaluacion
                        },
                    }
                ).then(function (response) {
                    me.listaPreguntas = response.data;
                    me.cargarPreguntas = false;
                    me.$refs["modal-ver-evaluacion"].show();
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        resetModalEvaluaciones() {
            let me = this
            me.listaEvaluacionesModalSeleccionadas = []
        },
        resetModalTrabajadores() {
            let me = this
            me.listaEvaluacionesModalSeleccionadas = []
        },
        cargarDatosCapacitacionCliente() {
            let me = this;
            me.cargarInfoCapacitacion = true,
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "responsable/cargar-datos-capacitacion", {
                        params: {
                            idCliente: me.idCliente,
                            idClienteCapacitacion: me.datosCapacitacion.idClienteCapacitacion
                        }
                    }
                )
                .then(function (response) {
                    if (response) {
                        me.datosCapacitacion.idCapacitacion = response.data[0].idCapacitacion
                        me.datosCapacitacion.nombre = response.data[0].nombre
                        me.datosCapacitacion.descripcion = response.data[0].descripcion
                        me.datosCapacitacion.nombre_tipo = response.data[0].nombre_tipo
                        me.datosCapacitacion.urlPortada = response.data[0].portada
                        me.datosCapacitacion.urlArchivo = response.data[0].url

                        me.ListarArchivos();
                        me.ListarTrabajadoresModal();
                        me.ListarEvaluacionesCapacitacion();
                        me.ListarTrabajadoresCapacitacion();
                        me.ObtenerDatosPieChart();
                        me.ObtenerDatosBarChart();

                        me.cargarInfoCapacitacion = false
                        me.cargarEstadisticas = false
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                    me.cargarInfoCapacitacion = true
                });
        },

        procesarArchivo(e) {
         
            let me = this;
            if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                alert("Formato de archivo no admitido.");
                me.$refs["input-archivos"].reset();
                return;
            }
            me.$bvModal
                .msgBoxConfirm(
                    "¿Esta seguro que desea subir el archivo " +
                    e.target.files[0].name +
                    "?", {
                        size: "sm",
                        buttonSize: "sm",
                        okTitle: "Subir",
                        cancelTitle: "Cancelar",
                        cancelVariant: "danger",
                        okVariant: "success",
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                .then((value) => {
                    if (value) {
                        let tipoArchvivo = 0;
                        if (me.ArchivoCapacitacion) {
                            tipoArchvivo = 1;
                            me.cargandoArchivos = true;
                        }
                        const formData = new FormData();
                        formData.append("file", e.target.files[0]);
                        formData.append("idCliente", me.idCliente);
                        formData.append("idCapacitacion", me.datosCapacitacion.idCapacitacion);
                        formData.append("nombreCapacitacion", me.datosCapacitacion.nombre);
                        formData.append("usuario", me.usuario);
                        formData.append("idTipoArchivo", tipoArchvivo);
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "responsable/upload",
                                formData
                            )
                            .then(async (res) => {
                                if (res.data.resultado == 0) {
                                    me.mensajeAlerta = res.data.mensaje;
                                    if (me.ArchivoCapacitacion) {
                                        me.emitirAlerta = true;
                                        me.$refs["input-archivos"].reset();
                                    }
                                } else {
                                    me.$refs["input-archivos"].reset();
                                    await me.ListarArchivos();
                                }
                                me.cargandoArchivos = false;
                            })
                            .catch((err) => {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    } else {
                        me.$refs["input-archivos"].reset();
                    }
                });
        },
        ListarArchivos() {
            let me = this;
            me.cargandoArchivos = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "responsable/listar-archivos", {
                        params: {
                            idCapacitacion: me.datosCapacitacion.idCapacitacion,
                            idCliente: me.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.cargandoArchivos = false
                    me.listaArchivos = response.data.filter(
                        (tipo) => tipo.idTipoArchivo == 1
                    );
                    me.filasTotalesArc = me.listaArchivos.length
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                    me.cargandoArchivos = true

                });
        },
        /* ListarEvaluacionesModal() {
              let me = this;
              me.cargarEvaluacion = true;
              axios.defaults.headers.common[
                  "Authorization"
              ] = `Bearer ${localStorage.token}`;
              axios
                  .get(
                      CONSTANTES.URL_RUTA_SERVICIOS + "responsable/listar-evaluacion-asignacion", {
                          params: {
                              idCapacitacion: me.datosCapacitacion.idCapacitacion,
                          },
                      }
                  )
                  .then(function (response) {
                      me.listaEvaluacionesModal = response.data;
                      me.cargarEvaluacion = false;
                  })
                  .catch(function (error) {
         this.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });                      me.cargarEvaluacion = true;
                  });
          },*/
        ListarTrabajadoresModal() {
            let me = this;
            me.cargarTrabajadores = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/listar-trabajadores-asignacion", {
                        params: {
                            idCliente: me.idCliente,
                            idCapacitacion: me.datosCapacitacion.idCapacitacion
                        },
                    }
                )
                .then(function (response) {
                    me.listaTrabajadoresModal = response.data;
                    me.cargarTrabajadores = false;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                    me.cargarTrabajadores = true;
                });
        },
        ListarEvaluacionesCapacitacion() {
            let me = this;
            me.cargarEvaluacion = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "responsable/listar-evaluacion-capacitacion", {
                        params: {
                            idCapacitacion: me.datosCapacitacion.idCapacitacion,
                        },
                    }
                )
                .then(function (response) {
                    me.listaEvaluaciones = response.data
                    me.filasTotalesEva = me.listaEvaluaciones.length
                    me.cargarEvaluacion = false
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                    me.cargarEvaluacion = true
                });
        },
        ListarTrabajadoresCapacitacion() {
            let me = this;
            //     me.cargarTrabajadores = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "responsable/listar-trabajadores-capacitacion", {
                        params: {
                            id_cliente_capacitacion: me.datosCapacitacion.idClienteCapacitacion,
                        },
                    }
                )
                .then(function (response) {
                    me.listaTrabajadores = response.data
                    me.filasTotalesTra = me.listaTrabajadores.length
                    //           me.cargarTrabajadores = false
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    }); //         me.cargarTrabajadores = true
                });
        },

        ObtenerDatosPieChart() {
            let me = this
            me.chartData.datasets[0].data = []
            me.cargaPieChart = false
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/cargar-capacitacion-piechart", {
                        params: {
                            idCliente: me.idCliente,
                            id_cliente_capacitacion: me.datosCapacitacion.idClienteCapacitacion
                        },
                    }
                )
                .then(function (response) {
                    if (response.data.length > 0) {
                        me.chartData.datasets[0].data.push(response.data[0].cant_pendiente, response.data[0].cant_curso, response.data[0].cant_finalizado)
                        me.cargaPieChart = true
                    }
                    me.chartData.datasets[0].data.push(0, 0, 0)
                    me.cargaPieChart = true

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ObtenerDatosBarChart() {
            let me = this
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/cargar-capacitacion-barchart", {
                        params: {
                            id_cliente_capacitacion: me.datosCapacitacion.idClienteCapacitacion
                        },
                    }
                )
                .then(function (response) {

                    for (var i = 0; i < response.data.length; i++) {
                        me.chartDataBar.labels.push(
                            response.data[i].mes
                        )
                        me.chartDataBar.datasets[0].data.push(
                            response.data[i].cant_eva
                        )
                    }

                    me.cargaBarChart = true
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        descargarArchivo(param) {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/descargar-archivo", {
                        params: {
                            ruta: param.item.rutaNube,
                            archivo: param.item.nombreArchivo,
                            idArchivo: param.item.idArchivo,
                        },
                    }
                )
                .then(function (response) {
                    window.open(response.data);
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        eliminarArchivo(param) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm(
                    "¿Está seguro que desea eliminar el archivo " +
                    param.item.nombreArchivo +
                    "?", {
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        size: "sm",
                        buttonSize: "sm",
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    }
                )
                .then((value) => {
                    if (value) {
                        if (param.item.idTipoArchivo == 1) {
                            me.cargandoArchivos = true;
                        }

                        axios.defaults.headers.common[
                            "Authorization"
                        ] = `Bearer ${localStorage.token}`;
                        axios
                            .get(
                                CONSTANTES.URL_RUTA_SERVICIOS +
                                "responsable/eliminar-archivo", {
                                    params: {
                                        idArchivo: param.item.idArchivo,
                                    },
                                }
                            )
                            .then(function (response) {
                                if (response) {

                                    if (param.item.idTipoArchivo == 1) {
                                        me.cargandoArchivos = false;
                                    }
                                    me.ListarArchivos();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        /*   AsignarEvaluacion() {
               let me = this
               axios
                   .post(
                       CONSTANTES.URL_RUTA_SERVICIOS + "responsable/asignar-evaluacion",
                       me.listaEvaluacionesModalSeleccionadas, {
                           headers: {
                               Authorization: `Bearer ${localStorage.token}`
                           },
                       }
                   )
                   .then(function (response) {
                       let titulo = response.data.resultado == 1 ? "Confirmado" : "Error";
                       let color = response.data.resultado == 1 ? "success" : "danger";
                       me.$bvModal
                           .msgBoxOk(response.data.mensaje, {
                               title: titulo,
                               size: "sm",
                               buttonSize: "sm",
                               okVariant: color,
                               headerClass: "p-2 border-bottom-0",
                               footerClass: "p-2 border-top-0",
                               centered: true,
                           })
                           .then((rpta) => {
                               if (rpta) {
                                   me.$refs["modal-asignar-evaluacion"].hide();
                                   me.ListarEvaluacionesCapacitacion();
                                   //   me.ListarEvaluacionesModal();
                                   me.resetModalEvaluaciones()
                               }
                           });
                   })
                   .catch(function (error) {
          this.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });                   });
           },*/
        AsignarTrabajador() {
            let me = this
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/asignar-trabajador",
                    me.listaTrabajadoresModalSeleccionados, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.$refs["modal-asignar-trabajador"].hide();
                    me.ListarTrabajadoresCapacitacion();
                    me.ListarTrabajadoresModal();
                    me.ObtenerDatosPieChart();
                    me.resetModalTrabajadores();
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        decryptData(encryptedData) {
            try{
                const SECRET_KEY    = process.env.VUE_APP_SECRET_KEY;
                const bytes         = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
                const decrypt       = bytes.toString(CryptoJS.enc.Utf8);
                return decrypt;
            }catch(error){

            }
        },
        /*    EliminarEvaluacion(param) {
                let NombreEvaluacion = param.item.nombre;
                this.$bvModal
                    .msgBoxConfirm(
                        "Esta seguro que desea quitar la evaluación " +
                        NombreEvaluacion +
                        " ? ", {
                            title: "Por favor, confirmar",
                            size: "sm",
                            buttonSize: "sm",
                            okVariant: "success",
                            okTitle: "SI",
                            cancelTitle: "NO",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        }
                    )
                    .then((r) => {
                        if (r == true) {
                            axios
                                .post(
                                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/eliminar-evaluacion-capacitacion", {
                                        id_capa_evaluacion: param.item.id_capa_evaluacion
                                    }, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.token}`
                                        },
                                    }
                                ).then((response) => {
                                    if (response) {
                                        this.ListarEvaluacionesCapacitacion();
                                        //     this.ListarEvaluacionesModal();
                                    }
                                })
                                .catch(function (error) {
                       this.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });                                });
                        }
                    });
            }*/

    },
    watch: {

    },
    mounted() {
        if (localStorage.usuario) {
            let me = this;
            const decrypt   = this.decryptData(localStorage.usuario);
            const lsUsuario = JSON.parse(decrypt); 
            me.idCliente    = lsUsuario.nIdCliente
            me.usuario      = lsUsuario.username
            me.datosCapacitacion.idClienteCapacitacion = me.$route.params.id
            me.cargarDatosCapacitacionCliente();
        }
        //  this.initBigChart(0);

        //    this.ListarEvaluacionesModal();
        //    this.renderChart(this.chartData, this.options)

        //        this.ListarTrabajadoresModal();
        //        this.ListarArchivos();

    }
};
</script>
