import { render, staticRenderFns } from "./ControlCapacitaciones.vue?vue&type=template&id=1b1b603b"
import script from "./ControlCapacitaciones.vue?vue&type=script&lang=js"
export * from "./ControlCapacitaciones.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports