<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12">
                    <b-overlay :show="cargarEvaluacion" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent">
                            <template v-slot:header>
                                <b-row align-v="center" slot="header">
                                    <b-col>
                                        <h3 class="mb-0">Evaluaciones completadas</h3>
                                    </b-col>
                                </b-row>
                            </template>
                            <b-row>
                                <b-col lg="4">
                                    <base-input v-if="!cargarEvaluacion">
                                        <b-input-group class="text-right" size="xl">
                                            <b-input-group-prepend is-text>
                                                <b-icon icon="search"></b-icon>
                                            </b-input-group-prepend>
                                            <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                            <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                        </b-input-group>
                                    </base-input>
                                </b-col>
                                <b-col lg="4">
                                </b-col>
                                <b-col lg="4">
                                    <base-input v-if="!cargarEvaluacion">
                                        <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                    </base-input>
                                </b-col>
                                <b-col lg="12">
                                    <b-table 
                                        sort-icon-left 
                                        show-empty small 
                                        responsive outlined 
                                        :items                  = "listaEvaluaciones" 
                                        :fields                 = "camposEvaluacion" 
                                        :current-page           = "paginaActual" 
                                        :per-page               = "porPagina" 
                                        :filter                 = "filter" 
                                        :filter-included-fields = "filterOn" 
                                        @filtered               = "onFiltered" 
                                        empty-text              = "Aún no hay evaluaciones en esta sección." 
                                        empty-filtered-text     = "No hay datos que coincidan con tu busqueda."
                                    >
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template v-slot:cell(fecha_creacion)="data">
                                            {{ data.item.fecha_creacion ? DateTime.fromISO(data.item.fecha_creacion, 'yyyy-MM-dd HH:mm:ss').setLocale('es').toFormat('d-LL-yyyy') : '' }}
                                        </template>

                                        <template v-slot:cell(opciones)="param">
                                            <base-button v-if="param.item.estado == 1" icon size="sm" type="primary" @click="AbrirParam(param)">
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="reply-fill" aria-label="Help"></b-icon>
                                                </span>
                                                Reintentar
                                            </base-button>

                                            <base-button icon size="sm" type="default" @click="param.toggleDetails">
                                                <span class="btn-inner--icon">
                                                    <b-icon :icon="param.detailsShowing ? 'eye-slash-fill' : 'eye-fill'" aria-label=" Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text">{{ param.detailsShowing ? 'Ocultar' : 'Mostrar'}} detalles</span>
                                            </base-button>
                                        </template>

                                        <template v-slot:row-details="row">
                                                <b-row class="mb-2">
                                                    <b-col xl="6" ><b>Total de Preguntas: </b>{{ row.item.n_preguntas }}</b-col>
                                                    <b-col xl="6" ><b>% Aciertos: </b>{{ ((parseInt(row.item.n_correctas)*100)/row.item.n_preguntas).toFixed(2) }}%</b-col>
                                                </b-row>
                                                <b-row class="mb-2">
                                                    <b-col xl="6"><b>Total correctas: </b>{{ row.item.n_correctas }}</b-col>
                                                    <b-col xl="6"><b>Total incorrectas: </b>{{ row.item.n_incorrectas }}</b-col>
                                                </b-row>
                                                <b-row class="mb-2">
                                                    <b-col xl="6"><b>N° de intentos: </b>{{ row.item.n_intento }}</b-col>
                                                    <b-col xl="6"><b>Fecha de ultimo intento: </b>{{ row.item.fecha_modificacion ? DateTime.fromISO(row.item.fecha_modificacion, 'yyyy-MM-dd HH:mm:ss').setLocale('es').toFormat('d LLLL yyyy, hh:mm a') : '' }}</b-col>
                                                </b-row>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                            <template v-slot:footer>
                                <b-row v-if="!cargarEvaluacion">
                                    <b-col lg="12">
                                        <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
    </b-container>
    <!--End container-->
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import CryptoJS from 'crypto-js';
import { DateTime } from 'luxon';

export default {
    name: "evaluaciones",
    components: {

    },
    data() {
        return {
            cargarEvaluacion: false,
            listaEvaluaciones: [],
            camposEvaluacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center"
                },
                {
                    key: "nombre_cap",
                    label: "Capacitación",
                    class: "text-center"
                },
                {
                    key: "fecha_creacion",
                    label: "Fecha  de registro",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    label: "opciones",
                    class: "text-center"
                },
            ],
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],

            filter: null,
            filterOn: [],
            DateTime,
        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        AbrirParam(param) {
            let me = this
            me.$router.push({
                name: "nueva evaluacion",
                params: {
                    idcaper: param.item.id_persona_capacitacion,
                    id: param.item.id_capacitacion_evaluacion,
                    nombre: param.item.nombre,
                },
            });
        },
        ListarEvaluacionesRendidas() {
            let me = this;
            me.cargarEvaluacion = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "trabajador/listar-evaluacion-rendidas", {
                        params: {
                            idCliente: me.idCliente,
                            idPersona: me.idPersona,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response.data[0].fecha_modificacion); 
                    me.listaEvaluaciones = response.data
                    me.filasTotales = me.listaEvaluaciones.length
                    me.cargarEvaluacion = false
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                    me.cargarEvaluacion = true
                });
        },
        decryptData(encryptedData) {
            try{
                const SECRET_KEY    = process.env.VUE_APP_SECRET_KEY; 
                const bytes         = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
                const decrypt       = bytes.toString(CryptoJS.enc.Utf8);
                return decrypt;
            }catch(error){
                console.log(error);
            }
        },
    },
    watch: {

    },

    mounted() {
        if (localStorage.usuario) {
            let me = this;
            const decrypt   = this.decryptData(localStorage.usuario);
            const lsUsuario = JSON.parse(decrypt); 
            me.idCliente = lsUsuario.nIdCliente
            me.idPersona = lsUsuario.idPersona
            me.ListarEvaluacionesRendidas();
        }
    }
};
</script>
