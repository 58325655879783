<template>
<div>
    <!-- Header -->
    <div class="header bg-gradient-default py-7 py-lg-8 pt-lg-9">
        <b-container>
            <b-row align-v="center" class="row-grid">
                <b-col md="6">
                    <b-img src="img/theme/landing-2.png" fluid />
                </b-col>
                <b-col md="6">
                    <div class="pr-md-5">
                        <h1 class="text-light">404</h1>
                        <p class="text-light">Página no encontrada. Sin embargo, no se preocupe, tenemos muchas otras páginas para explorar.</p>
                        <router-link v-if="idTipoPerfil == 1" :to="{name:'control clientes'}" class="font-weight-bold text-info mt-5">Volver al tablero</router-link>
                        <router-link v-if="idTipoPerfil == 2" :to="{name:'control capacitaciones'}" class="font-weight-bold text-info mt-5">Volver al tablero</router-link>
                        <router-link v-if="idTipoPerfil == 3" :to="{name:'panel responsable'}" class="font-weight-bold text-info mt-5">Volver al tablero</router-link>
                        <router-link v-if="idTipoPerfil == 4" :to="{name:'panel trabajador'}" class="font-weight-bold text-info mt-5">Volver al tablero</router-link>
                        <router-link v-if="idTipoPerfil == null " :to="{name:'login'}" class="font-weight-bold text-info mt-5">Volver al panel de acceso</router-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
    </div>
    <!-- Page content -->

</div>
</template>

<script>
export default {
    name: "notFoundPage",
    components: {},
    data() {
        return {
            idTipoPerfil: null
        };
    },
    mounted() {
        console.log('not foundpage'); 
        if (localStorage.usuario) {
            let me = this
            var lsUsuario = JSON.parse(localStorage.usuario)
            me.idTipoPerfil = lsUsuario.idPerfil
        }
    }
};
</script>
