<template>
<div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">

        <b-row>
            <b-col xl="12" class="mb-5">
                <card header-bg-variant="info" header-classes="bg-transparent">
                    <b-row align-v="center" slot="header">
                        <b-col>
                            <h3 class="mb-0">Seleccionar el cliente </h3>
                        </b-col>
                    </b-row>
                    <!--    <h6 class="heading-small text-muted mb-4">User information</h6>-->
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(generarData)">
                            <div class="pl-lg-3 pr-lg-3 ">
                                <b-row>
                                    <b-col md="12">
                                        <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                            <b-form-group label="Clientes" class="labelx mb-2">
                                                <v-select :reduce="listaClientes => listaClientes.idCliente" label="razonSocial" @input="cargarData()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosFiltro.idCliente , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosFiltro.idCliente" :options="listaClientes" @search:blur="blurCliente">
                                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col lg="12 mt-3 text-right">
                                        <b-button 
                                            size        = "md" 
                                            variant     = "success" 
                                            class       = "mb-2" 
                                            type        = "submit" 
                                            :disabled   = isLoading 
                                        >
                                            <b-spinner v-if="isLoading" small type="grow" variant="secondary"></b-spinner>
                                            <span v-else>
                                                <b-icon icon="pie-chart-fill" aria-label="Help"></b-icon> Generar reporte
                                            </span>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form>
                    </validation-observer>
                </card>
            </b-col>
        </b-row>

    </base-header>


</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import XLSX from 'xlsx'
export default {
    components: {

    },
    data() {
        return {
            listaClientes            : [],
            isLoading                : false,
            listaCapacitaciones      : [],
            listaTrabajadores        : [],
            listaEvaluaciones        : [],
            listaEvaluacionesDetalle : [],
            datosFiltro              : {
                idCliente: null,
                idUsuario: '',
            },
        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        generarData() {
            /*-----Capacitaciones---*/
            let capacitacionesWS = [];
            if (this.listaCapacitaciones.length > 0) {
                capacitacionesWS = XLSX.utils.json_to_sheet(this.listaCapacitaciones.map(element => Object.assign({}, ...['nombre', 'cantidad'].map(key => ({
                    [key]: element[key]
                })))), )
                capacitacionesWS['A1'].v = 'Capacitaciones'
                capacitacionesWS['B1'].v = 'Cantidad de evaluaciones'

                let capacitacionesWSCols = [{
                        wch: 50
                    },
                    {
                        wch: 25
                    },
                ];

                capacitacionesWS['!cols'] = capacitacionesWSCols;
            }
            /*-----Trabajadores---*/
            let trabajadoresWS = [];
            if (this.listaTrabajadores.length > 0) {
                trabajadoresWS = XLSX.utils.json_to_sheet(this.listaTrabajadores.map(element => Object.assign({}, ...['NombreCompleto', 'curso', 'pendientes', 'finalizado'].map(key => ({
                    [key]: element[key]
                })))), )
                trabajadoresWS['A1'].v = 'Apellidos y nombres'
                trabajadoresWS['B1'].v = 'Cap. pendientes'
                trabajadoresWS['C1'].v = 'Cap. en proceso'
                trabajadoresWS['D1'].v = 'Cap. finalizados'
                let trabajadoresWSCols = [{
                        wch: 50
                    },
                    {
                        wch: 17
                    }, {
                        wch: 17
                    }, {
                        wch: 17
                    },
                ];

                trabajadoresWS['!cols'] = trabajadoresWSCols;
            }
            /*-----Evaluaciones---*/
            let evaluacionesWS = [];
            if (this.listaEvaluaciones.length > 0) {
                evaluacionesWS = XLSX.utils.json_to_sheet(this.listaEvaluaciones.map(element => Object.assign({}, ...['NombreCompleto', 'cantidad', 'promedio'].map(key => ({
                    [key]: element[key]
                })))), )
                evaluacionesWS['A1'].v = 'Apellidos y nombres'
                evaluacionesWS['B1'].v = 'Evaluaciones rendidas'
                evaluacionesWS['C1'].v = 'Promedio'
                let evaluacionesWSCols = [{
                        wch: 50
                    },
                    {
                        wch: 25
                    }, {
                        wch: 17
                    }
                ];
                evaluacionesWS['!cols'] = evaluacionesWSCols;
            }
            /*-----Evaluaciones detalles---*/
            let devaluacionesWS = [];
            if (this.listaEvaluacionesDetalle.length > 0) {
                devaluacionesWS = XLSX.utils.json_to_sheet(this.listaEvaluacionesDetalle.map(element => Object.assign({}, ...['NombreCompleto', 'nombre_cap', 'n_intento', 'n_preguntas', 'n_correctas', 'n_incorrectas', 'fecha_creacion', 'fecha_modificacion'].map(key => ({
                    [key]: element[key]
                })))), )
                devaluacionesWS['A1'].v = 'Apellidos y nombres'
                devaluacionesWS['B1'].v = 'Capacitaciones'
                devaluacionesWS['C1'].v = 'N° de intentos'
                devaluacionesWS['D1'].v = 'N° de preguntas'
                devaluacionesWS['E1'].v = 'N° correctas'
                devaluacionesWS['F1'].v = 'N° incorrectas'
                devaluacionesWS['G1'].v = 'Fecha de creación'
                devaluacionesWS['H1'].v = 'Fecha de modificación'

                let devaluacionesWSCols = [{
                        wch: 50
                    },
                    {
                        wch: 50
                    }, {
                        wch: 17
                    }, {
                        wch: 17
                    }, {
                        wch: 17
                    }, {
                        wch: 17
                    }, {
                        wch: 23
                    }, {
                        wch: 23
                    }
                ];

                devaluacionesWS['!cols'] = devaluacionesWSCols;
            }
            /*-----ADD WORKSHEET---*/

            let wb = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(wb, capacitacionesWS, 'CAPACITACIONES')
            XLSX.utils.book_append_sheet(wb, trabajadoresWS, 'TRABAJADORES')
            XLSX.utils.book_append_sheet(wb, evaluacionesWS, 'EVALUACIONES')
            XLSX.utils.book_append_sheet(wb, devaluacionesWS, 'EVALUACIONES DETALLADO')
            // XLSX.utils.book_append_sheet(wb, dcapacitacionesWS, 'CAPACITACIONES DETALLADO')

            XLSX.writeFile(wb,this.nombreArchivo() )
        },

        async cargarData() {
            try {
                this.isLoading = true;
                await Promise.all([
                    this.listarCapacitaciones(),
                    this.listarTrabajadores(),
                    this.listarEvaluaciones(),
                    this.listarEvaluacionesDetalle(),
                ]);
            } catch (error) {
                console.error("Error al cargar los datos", error);
            } finally {
                this.isLoading = false;
            }
        },
        nombreArchivo() {
            let f = new Date();
            let date = f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
            let archivo =  this.listaClientes.filter(x => x.idCliente == this.datosFiltro.idCliente)[0] ? 'Reporte de '+this.listaClientes.filter(x => x.idCliente == this.datosFiltro.idCliente)[0].razonSocial + ' ' + date + '.xlsx' : 'Reporte desconocido';
            return archivo;
        },
        listarCapacitaciones() {
            let me = this;
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;

            // Devuelves la promesa de la solicitud de axios
            return axios.get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/reporte-capacitaciones-cliente", {
                params: {
                    idCliente: me.datosFiltro.idCliente,
                }
            }).then(function (response) {
                if (response) {
                    me.listaCapacitaciones = response.data;
                }
            }).catch(function (error) {
                me.$notify({
                    verticalAlign: 'bottom',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Error, algo salió mal.'
                });
            });
        },

        ListarClientes() {
            let me = this
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-clientes-afiliados", {

                })
                .then(function (response) {
                    me.listaClientes = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        listarTrabajadores() {
            let me = this;
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;

            return axios.get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/reporte-trabajador-capacitacion", {
                params: {
                    idCliente: me.datosFiltro.idCliente
                },
            })
            .then(function (response) {
                me.listaTrabajadores = response.data;
            })
            .catch(function (error) {
                me.$notify({
                    verticalAlign: 'bottom',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Error, algo salió mal.'
                });
            });
        },

        listarEvaluaciones() {
            let me = this;
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;

            return axios.get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/reporte-trabajador-evaluaciones", {
                params: {
                    idCliente: me.datosFiltro.idCliente
                },
            })
            .then(function (response) {
                me.listaEvaluaciones = response.data;
            })
            .catch(function (error) {
                me.$notify({
                    verticalAlign: 'bottom',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Error, algo salió mal.'
                });
            });
        },
        listarEvaluacionesDetalle() {
            let me = this;
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;

            return axios.get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/reporte-trabajador-evaluaciones-detalle", {
                params: {
                    idCliente: me.datosFiltro.idCliente
                },
            })
            .then(function (response) {
                me.listaEvaluacionesDetalle = response.data;
            })
            .catch(function (error) {
                me.$notify({
                    verticalAlign: 'bottom',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Error, algo salió mal.'
                });
            });
        },
        blurCliente() {
            this.computedForm.refs.cliente.validate();
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            me.ListarClientes();
        }

    }
};
</script>
