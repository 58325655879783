<template>
<div>
    <!-- Header -->
    <div class="header bg-gradient-warning py-7 py-lg-8 pt-lg-9">

        <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
        <b-row class="justify-content-center">
            <b-col lg="5" md="7">
                <b-card no-body class="bg-secondary border-0 mb-0">
                    <b-card-header class="bg-transparent pb-4">
                        <img src="/img/brand/logo.png" class="img-fluid">
                    </b-card-header>
                    <b-overlay :show="respuestaLogin" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <b-card-body class="px-lg-5 py-lg-5">
                            <div class="text-center text-muted mb-4">
                                <small>Ingrese sus credenciales</small>
                            </div>
                            <b-alert :show="dismissCountDown" dismissible :variant="variant" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">{{ message }}</b-alert>
                            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                                    <base-input alternative class="mb-3" name="de correo" :rules="{required: true}" prepend-icon="envelope-fill" placeholder="Ingrese su correo" v-model="usuario">
                                    </base-input>
                                    <base-input alternative class="mb-3" name="de contraseña" :rules="{required: true, min: 8}" prepend-icon="key-fill" type="password" placeholder="Ingrese su contraseña" v-model="password">
                                    </base-input>
                                    <div class="text-center">
                                        <base-button type="primary" native-type="submit" class="my-3">Ingresar</base-button>
                                    </div>
                                </b-form>
                            </validation-observer>
                        </b-card-body>
                    </b-overlay>
                </b-card>
                <b-row class="mt-3">
                    <b-col cols="8">
                        <router-link :to="{name:'recuperar'}" class="text-light"><small>Olvidaste tu contraseña?</small></router-link>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import CryptoJS from 'crypto-js';

export default {
    name: "login",
    data() {
        return {
            usuario          : '',
            password         : '',
            respuestaLogin   : false,
            variant          : "danger",
            message          : "",
            dismissSecs      : 5,
            dismissCountDown : 0,
            SECRET_KEY       : process.env.VUE_APP_SECRET_KEY,
        };
    },
    methods: {
        encryptData(data) {
            try{
                const encrypted  = CryptoJS.AES.encrypt(data, this.SECRET_KEY).toString();
                if (!encrypted) {
                    throw new Error("El proceso de encriptación devolvió un valor vacío.");
                }
                return encrypted;
            }catch(error){
                console.log(error);
            }
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        onSubmit() {
            let me = this;
            me.respuestaLogin = true;

            axios.post(CONSTANTES.URL_RUTA_SERVICIOS + "auth/login", {
                    usuario: me.usuario,
                    password: me.password,
            }).then(function (response) {
                if (response.data.token) {
                        const datos          = response.data;
                        const idPerfil       = datos.usuario.idPerfil;
                        const stringity      = JSON.stringify(datos.usuario);
                        const encryptedData  = me.encryptData(stringity); 
                        localStorage.token   = datos.token;
                        localStorage.usuario = encryptedData; 
                        if (idPerfil == 1) {
                            me.$router.push({
                                name: "control clientes",
                            });
                        } else if (idPerfil == 2) {
                            me.$router.push({
                                name: "control capacitaciones",
                            });

                        } else if (idPerfil == 3) {
                            me.$router.push({
                                name: "panel responsable",
                            });
                        } else if (idPerfil == 4) {
                            me.$router.push({
                                name: "panel trabajador",
                            });
                        }
                } else {
                        me.message = response.data.message;
                        me.dismissCountDown = me.dismissSecs;
                }
                me.respuestaLogin = false;

            }).catch(function (error) {
                me.$notify({
                    verticalAlign: 'bottom',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Error, algo salió mal.'
                });
            });
        }
    },
    mounted() {
        localStorage.token = "";
        localStorage.usuario = "";
    }
};
</script>
