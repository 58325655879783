<template>
    <div>
        <b-modal 
            class           = "bg-secondary" 
            id              = "modal-capacitacion" 
            ref             = "modal-capacitacion" 
            :title          = "isEditing ? 'Editar Capacitación' : 'Agregar Nueva Capacitación'" 
            size            = ""  
            border-variant  = "info" 
            no-close-on-esc 
            hide-footer 
            no-close-on-backdrop 
            centered
        >
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submitCapacitacion)">
                    <div class="pl-lg-3 pr-lg-3 ">
                        <b-row>
                            <b-col lg="12" class="text-center" v-if="isEditing">
                                <p class="font-weight-bold">{{ datosCapacitacion.nombre }}</p>
                            </b-col>
                            <b-col lg="12">
                                <validation-provider name="de tipo de capacitación" :rules="{ required: true }" v-slot="validationContext">
                                    <base-input label="Tipo de Capacitación">
                                        <b-form-select 
                                            :state      = "getValidationState(validationContext)" 
                                            id          = "tipoDoc" 
                                            v-model     = "datosCapacitacion.idTipoCapacitacion" 
                                            :options    = "listaTipoCapacitacion" 
                                            size        = "sm"
                                            value-field = "idTipoCapacitacion" 
                                            text-field  = "Nombre"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </base-input>
                                </validation-provider>
                            </b-col>
                            <b-col lg="12">
                                <base-input 
                                    label           = "Nombre" 
                                    name            = "de nombre" 
                                    :rules          = "{ required: true }" 
                                    placeholder     = "Ingrese Nombre de capacitación" 
                                    input-classes   = "form-control-sm" 
                                    v-model         = "datosCapacitacion.nombre"
                                > </base-input>
                            </b-col>
                            <b-col lg="12">
                                <validation-provider name="documento" v-slot="validationContext">
                                    <b-form-group label="Documento Capacitación: (PDF)" class="labelx mb-2">
                                        <b-form-file
                                            class            = "form-control-sm mb-3"
                                            size             = "sm"
                                            :state           = "getValidationState(validationContext)"
                                            aria-describedby = "input-1-live-feedback"
                                            placeholder      = "Elija un archivo o arrástrelo aquí..."
                                            accept           = ".pdf"
                                            browse-text      = "Subir"
                                            ref              = "fileDocumento"
                                            @change          = "handleFileUpload($event,'documento',['pdf'])"
                                        ></b-form-file>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="12">
                                <validation-provider name="portada" v-slot="validationContext">
                                    <b-form-group label="Portada:" class=" labelx mb-2">
                                        <b-form-file 
                                            class            = "form-control-sm mb-3" 
                                            size             = "sm" 
                                            :state           = "getValidationState(validationContext)" 
                                            aria-describedby = "input-1-live-feedback" 
                                            placeholder      = "Elija un archivo o arrástrelo aquí..." 
                                            accept           = ".png, .jpg, .jpge" 
                                            browse-text      = "Subir" 
                                            ref              = "file" 
                                            @change          = "handleFileUpload($event,'portada',['jpg','png','webp'])"
                                        ></b-form-file>
                                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="12">
                                <base-input label="Descripcion">
                                    <b-form-textarea 
                                        rows        = "3" 
                                        placeholder = "Ingrese Descripcion" 
                                        v-model     = "datosCapacitacion.descripcion"
                                    ></b-form-textarea>
                                </base-input>
                            </b-col>
                            <b-col lg="12  text-right">
                                <b-button 
                                    size        = "sm" 
                                    variant     = "success" 
                                    class       = "mb-2" 
                                    type        = "submit"
                                    :disabled   = "isLoading"
                                >
                                    {{isLoading ? 'Cargando...' : isEditing ? 'Actualizar Capacitación' : 'Registrar Capacitación' }}
                                </b-button>
                                <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-capacitacion')">Cerrar</b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
    </template>
    
    <script>
    import axios from "axios";
    import CONSTANTES from "@/Constantes.js";
    
    export default {
        name    : "capacitaciones",
        props   : {
            datosCapacitacion: {
                type: Object,
                required: true,
                default: () => ({
                    idCapacitacion      : "",
                    nombre              : '',
                    descripcion         : '',
                    documento           : null,
                    archivoMeta         : null,
                    idTipoCapacitacion  : null,
                }),
            },
            isEditing: {
                type: Boolean,
                default: false, // Valor por defecto si no se pasa desde el padre
            },
            listaCapacitaciones : {
                type : Array,  
            } 
        },
        data() {
            return {
                pdfDocument: null,
                pages: [],
                canvasRefs: [],
                cargaCapacitaciones: true,
                listaTipoCapacitacion: [], 
                isLoading : false,
            };
        },
        methods: {
            onFileSelected(event){
                this.selectedFile = event.target.files[0];
            },
            onFiltered(itemsFiltrados) {
                this.filasTotales = itemsFiltrados.length
                this.paginaActual = 1
            },
            getValidationState({
                dirty,
                validated,
                valid = null
            }) {
                return dirty || validated ? valid : null;
            },
            handleFileUpload(event,type,allowedExtensions) {
                const fileInput = event.target; 
                const file      = fileInput.files[0];
    
                if (!file) {
                    this.$notify({
                        verticalAlign   : 'bottom',
                        horizontalAlign : 'right',
                        type            : 'danger',
                        icon            : 'emoji-frown',
                        message         : `El campó esta vacio.`,
                    });
                    return;
                }
    
                const fileExtension = file.name.split('.').pop().toLowerCase();
    
                if (!allowedExtensions.includes(fileExtension)) {
                    this.$notify({
                        verticalAlign   : 'bottom',
                        horizontalAlign : 'right',
                        type            : 'danger',
                        icon            : 'emoji-frown',
                        message         : `El archivo seleccionado no tiene un formato válido. Las extensiones permitidas son: ${allowedExtensions.join(', ')}.`,
                    });
                    fileInput.value = '';
                    return;
                }
    
                if (type === 'documento') {
                    this.datosCapacitacion.documento = file;
                } else if (type === 'portada') {
                    this.datosCapacitacion.archivoMeta = file;
                }
            },
            ObtenerTipoCapacitacion() {
                let me = this;
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${localStorage.token}`;
                axios
                    .get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-tipo-capacitacion")
                    .then(function (response) {
                        if (response) {
                            me.listaTipoCapacitacion = response.data;
                        }
                    })
                    .catch(function (error) {
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: 'Error, algo salió mal.'
                        });
                    });
            },
            resetModalCapacitacion() {
                let me = this;
                me.datosCapacitacion.nombre             = "",
                me.datosCapacitacion.descripcion        = "",
                me.datosCapacitacion.archivoMeta        = null,
                me.datosCapacitacion.documento          = null,
                me.datosCapacitacion.idTipoCapacitacion = null
            },
            submitCapacitacion() {
                let me = this;
                const url      = this.isEditing ? `administrador/actualizar-capacitacion` : `administrador/registrar-capacitacion`;
                const formData = new FormData();

                if(!this.isEditing){
                    if(!me.datosCapacitacion.documento || !me.datosCapacitacion.archivoMeta){
                        const mensaje = `Debe seleccionar el ${!me.datosCapacitacion.documento ? `el Docuemnto de la Capacitación` : `la portada` } `; 
                        me.$notify({
                                verticalAlign   : 'bottom',
                                horizontalAlign : 'right',
                                type            : 'info',
                                icon            : 'emoji-frown',
                                message         : mensaje
                        });
                        return false; 
                    }
                }

                if(this.isEditing){
                    formData.append("idCapacitacion", me.datosCapacitacion.idCapacitacion);
                }
                formData.append("documento", me.datosCapacitacion.documento);
                formData.append("file", me.datosCapacitacion.archivoMeta);
                formData.append("folder", 'logo');
                formData.append("nombre", me.datosCapacitacion.nombre);
                formData.append("urlArchivo", me.datosCapacitacion.urlArchivo);
                formData.append("descripcion", me.datosCapacitacion.descripcion);
                formData.append("idTipoCapacitacion", me.datosCapacitacion.idTipoCapacitacion);
                this.isLoading = true;

                if(this.isEditing){
                    me.ActualizarCapacitacion(formData);
                }else{
                    me.RegistrarCapacitacion(formData);
                }   
            },
            
            RegistrarCapacitacion(formData) {
                let me = this;
                axios.post(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/registrar-capacitacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                ).then((response) => {
                    if(response.data.success){
                        const nuevosDatos = response.data.message;
                        me.$notify({
                            verticalAlign   : 'bottom',
                            horizontalAlign : 'right',
                            type            : 'success',
                            icon            : 'emoji-smile',
                            message         : 'Se agrego la capacitación exitosamente!'
                        });
                        this.$emit('nueva-capacitacion', nuevosDatos); 
                        me.$refs["modal-capacitacion"].hide(); 
                    }
                }).catch((err) => {
                    me.$notify({verticalAlign: 'bottom', horizontalAlign: 'right', type: 'danger', icon: 'emoji-frown', message: 'Error, algo salió mal.'});
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            ActualizarCapacitacion(formData) {
                let me = this;

                axios.post( CONSTANTES.URL_RUTA_SERVICIOS + "administrador/actualizar-capacitacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                ).then((response) => {
                        if(response.data.success){
                            const nuevosDatos = response.data.message;
                            me.$notify({ verticalAlign:'bottom', horizontalAlign:'right', type:'success', icon:'emoji-smile', message:'Se edito la capacitación exitosamente!'});
                            this.$emit('actualizar-capacitacion', nuevosDatos);
                            me.$refs["modal-capacitacion"].hide();
                        }
                }).catch((err) => {
                    console.log(err); 
                    me.$notify({ verticalAlign: 'bottom', horizontalAlign: 'right', type: 'danger', icon: 'emoji-frown', message: 'Error, algo salió mal.' });
                }).finally(() => {
                    this.isLoading = false;
                });
            },
        },
        mounted() {
            let me = this
            me.ObtenerTipoCapacitacion();
        }
    };
</script>
    

    