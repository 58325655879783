<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
        </b-row>
    </base-header>
    <!--End head-->

</div>
</template>

<script>

import CryptoJS from 'crypto-js';

export default {
    name: "routerpage",
    components: {

    },
    data() {
        return {

        };
    },
    methods: {
        decryptData(encryptedData) {
            try{
                const SECRET_KEY    = process.env.VUE_APP_SECRET_KEY;
                const bytes         = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
                const decrypt       = bytes.toString(CryptoJS.enc.Utf8);
                return decrypt;
            }catch(error){
                console.log(error);
            }
        },
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this;
            const decrypt   = me.decryptData(localStorage.usuario); 
            const lsUsuario = JSON.parse(decrypt);
            if (lsUsuario.idPerfil == "1") {
                me.$router.push({
                    name: "control clientes",
                });
            }
            if (lsUsuario.idPerfil == "2") {
                me.$router.push({
                    name: "control capacitaciones",
                });
            }
            if (lsUsuario.idPerfil == "3") {
                me.$router.push({
                    name: "panel responsable",
                });
            }
            if (lsUsuario.idPerfil == "4") {
                me.$router.push({
                    name: "panel trabajador",
                });
            }
        }
    }
};
</script>
