<template>
    <div>
        <!-- head-->
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
            <b-row>
    
            </b-row>
        </base-header>
        <!--End head-->
    
        <!--ontainer-->
        <b-container fluid class="mt--8 mb-5">
            <div class="mt-4">
                <b-row>
                    <b-col xl="12">
                        <b-overlay :show="cargaCapacitaciones" rounded="lg" opacity="0.5">
                            <template v-slot:overlay>
                                <div class="d-flex align-items-center">
                                    <b-spinner small type="grow" variant="secondary"></b-spinner>
                                    <b-spinner type="grow" variant="dark"></b-spinner>
                                    <b-spinner small type="grow" variant="secondary"></b-spinner>
                                </div>
                            </template>
                            <card header-classes="bg-transparent">
                                <template v-slot:header>
                                    <b-row align-v="center" slot="header">
                                        <b-col>
                                            <h3 class="mb-0">Capacitaciones </h3>
                                        </b-col>
                                        <b-col class="text-right">
                                            <b-dropdown size="sm" icon="bookmark-plus-fill" variant="primary" class="m-md-2 bg-secondary">
                                                <template v-slot:button-content>
                                                    <b-icon icon="bookmark-plus-fill" aria-hidden="true"></b-icon> Agregar nuevo item
                                                </template>
                                                <template>
                                                    <b-dropdown-item size="sm" v-b-modal.modal-capacitacion>
                                                        <b-icon icon="bookmarks-fill" aria-hidden="true"></b-icon>
                                                        <span>Capacitación</span>
                                                    </b-dropdown-item>
                                                    <b-dropdown-item size="sm" v-b-modal.modal-registro-categoria>
                                                        <b-icon icon="bookmark-plus-fill" aria-hidden="true"></b-icon>
                                                        <span>Categoría</span>
                                                    </b-dropdown-item>
                                                </template>
                                            </b-dropdown>
                                        </b-col>
    
                                    </b-row>
                                </template>
                                <b-row>
                                    <b-col lg="4">
                                        <base-input v-if="!cargaCapacitaciones">
                                            <b-input-group class="text-right" size="xl">
                                                <b-input-group-prepend is-text>
                                                    <b-icon icon="search"></b-icon>
                                                </b-input-group-prepend>
                                                <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                            </b-input-group>
                                        </base-input>
                                    </b-col>
                                    <b-col lg="4">
    
                                    </b-col>
                                    <b-col lg="4">
                                        <base-input v-if="!cargaCapacitaciones">
                                            <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                        </base-input>
                                    </b-col>
    
                                    <b-col lg="12">
                                        <b-table 
                                            sort-icon-left 
                                            show-empty small 
                                            responsive outlined 
                                            :items="listaCapacitaciones" 
                                            :fields="camposCapacitaciones" 
                                            :current-page="paginaActual" 
                                            :per-page="porPagina" 
                                            :filter="filter" 
                                            :filter-included-fields="filterOn" 
                                            @filtered="onFiltered" 
                                            empty-text="Aún no hay capacitaciones en esta sección." 
                                            empty-filtered-text="No hay datos que coincidan con tu busqueda."
                                        >
                                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                            <template v-slot:cell(portada)="data">
                                                <img 
                                                    :src="data.item.portada" 
                                                    alt="Portada" 
                                                    style="width: 50px; height: auto;" 
                                                    v-if="data.item.portada" 
                                                />
                                                <span v-else>No disponible</span>
                                            </template>
                                            <template v-slot:cell(estado)="data">
                                                <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                                                <b class="text-success" v-else>ACTIVO</b>
                                            </template>
                                            <template v-slot:cell(actions)="param">
                                                <router-link 
                                                    :to                 ="{name:'mantenimiento capacitación',
                                                    params: {idCapacitacion: param.item.idCapacitacion}}" 
                                                    class               ="text-light mx-1"
                                                >
                                                    <base-button icon size="sm" type="default">
                                                        <span class="btn-inner--icon">
                                                            <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                                        </span>
                                                        <span class="btn-inner--text">Modificar</span>
                                                    </base-button>
                                                </router-link>
                                                <base-button icon size="sm" type="danger" class="mx-1"  @click="handleDeleteCapacitacion(param.item)">
                                                    <span class="btn-inner--icon">
                                                        <b-icon icon="trash" aria-label="Delete"></b-icon>
                                                    </span>
                                                    <span class="btn-inner--text">Eliminar</span>
                                                </base-button>
                                            </template>
                                            
                                        </b-table>
                                    </b-col>
                                </b-row>
                                <template v-slot:footer>
                                    <b-row v-if="!cargaCapacitaciones">
                                        <b-col lg="12">
                                            <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                        </b-col>
                                    </b-row>
                                </template>
                            </card>
                        </b-overlay>
                    </b-col>
                </b-row>
            </div>
        </b-container>
        <!--End container-->
    
        <ModalCapacitacion
            :isEditing              = false
            :datosCapacitacion      = "datosCapacitacion"
            :listaCapacitaciones    = "listaCapacitaciones"
            @nueva-capacitacion     = "agregarNuevaCapacitacion"
        />
            
        <b-modal class="bg-secondary" id="modal-registro-categoria" ref="modal-registro-categoria" title="Agregar Nueva categoria" size="sm" border-variant="default" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalCategoria">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(RegistrarCategoria)">
                    <b-row>
                        <b-col lg="12">
                            <base-input label="Nombre de la categoría" name="de nombre de la categoria" :rules="{ required: true }" placeholder="Ingrese nombre de la categoria" input-classes="form-control-sm" v-model="datosCategoria.nombre"> </base-input>
                        </b-col>
                        <b-col lg="12  text-right">
                            <b-button size="sm" variant="success" class="mb-3" type="submit">
                                Registrar Categoría
                            </b-button>
                            <b-button class="mb-3" size="sm" variant="danger" @click="$bvModal.hide('modal-registro-categoria')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <b-table sort-icon-left sticky-header="300px" show-empty small responsive outlined :items="listaTipoCapacitacion" :fields="camposTipoCapacitacion" empty-text="Aún no hay categorias en esta sección." @show="resetModalCategoria">
                <template v-slot:cell(index)="data">{{ data.index + 1 }}
                </template>
            </b-table>
        </b-modal>
    
    </div>
    </template>
    
    <script>
    import axios from "axios";
    import CONSTANTES from "@/Constantes.js";
    import ModalCapacitacion from '../../components/Pages/Administrador/modales/ModalCapacitacion.vue';
import { sweetQuery } from "../../util/sweetAlert";
    
    export default {
        name: "capacitaciones",
        components: {
            ModalCapacitacion,
        },
        data() {
            return {
                pdfDocument: null,
                pages: [],
                canvasRefs: [],
                camposTipoCapacitacion: [{
                        key: "index",
                        label: "N°",
                        class: "text-center"
                    },
                    {
                        key: "Nombre",
                        label: "Categoría",
                        class: "text-center"
                    }
                ],
                cargaCapacitaciones: true,
                datosCategoria: {
                    nombre: ""
                },
                datosCapacitacion: {
                    nombre              : "",
                    descripcion         : "",
                    urlArchivo          : "",
                    archivoMeta         : null,
                    documento           : null,
                    idTipoCapacitacion  : null,
                },
                camposCapacitaciones: [{
                        key: "index",
                        label: "N°",
                        class: "text-center",
                    },
                    {
                        key     : "portada",
                        label   : "Portada",
                        class   : "text-center",
                    },
                    {
                        key: "nombre",
                        label: "Nombre",
                        class: "text-center",
                    },
    
                    {
                        key: "nombre_tipo",
                        label: "Tipo",
                        class: "text-center",
                    },
                    {
                        key: "estado",
                        label: "Estado",
                        class: "text-center",
                    },
                    {
                        key: "actions",
                        label: "opciones",
                        class: "text-center",
                    },
                ],
    
                listaTipoCapacitacion: [],
                listaCapacitaciones: [],
    
                filasTotales: 1,
                paginaActual: 1,
                porPagina: 10,
                paginaOpciones: [10, 25, 50, {
                    value: 99999,
                    text: "Mostrar todo"
                }],
    
                filter: null,
                filterOn: [],
            };
    
        },
        methods: {
            handleDeleteCapacitacion(row){
                const formData = new FormData();
                formData.append('capacitacionData', JSON.stringify(row)); 
                sweetQuery('¡Eliminar!',`¿Desea eliminar la capacitación ${row.nombre} ?` , 'question' ,'administrador/eliminar-capacitacion', formData, 'POST').then(respuesta => {
                    if(respuesta.success){
                        this.ListarCapacitaciones();
                    }
                })
            },
            onFileSelected(event){
                this.selectedFile = event.target.files[0];
            },
            onFiltered(itemsFiltrados) {
                this.filasTotales = itemsFiltrados.length
                this.paginaActual = 1
            },
            getValidationState({
                dirty,
                validated,
                valid = null
            }) {
                return dirty || validated ? valid : null;
            },
            ObtenerTipoCapacitacion() {
                let me = this;
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${localStorage.token}`;
                axios.get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-tipo-capacitacion")
                    .then(function (response) {
                        if (response) {
                            me.listaTipoCapacitacion = response.data;
                        }
                    })
                    .catch(function (error) {
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: 'Error, algo salió mal.'
                        });
                    });
            },
            ListarCapacitaciones() {
                let me = this;
                me.cargaCapacitaciones = true
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${localStorage.token}`;
                axios.get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-capacitaciones").then(function (response) {
                        if (response) {
                            me.listaCapacitaciones = response.data
                            me.filasTotales = me.listaCapacitaciones.length
                            me.cargaCapacitaciones = false
                        }
                    })
                    .catch(function (error) {
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: 'Error, algo salió mal.'
                        });
                    });
            },
            agregarNuevaCapacitacion(nuevacapacitacion) {
                const idTipoCapacitacionSeleccionado = parseInt(nuevacapacitacion.xidTipoCapacitacion);
                const tipocapacitacion = this.listaTipoCapacitacion.find(objeto => parseInt(objeto.idTipoCapacitacion) === idTipoCapacitacionSeleccionado);
                const transformado     = Object.keys(nuevacapacitacion).reduce((acc, key) => {
                    const nuevaClave = key.startsWith('x') ? key.slice(1) : key; // Quitar la "x" al inicio si existe
                    acc[nuevaClave] = nuevacapacitacion[key];
                    return acc;
                }, {});
                transformado.nombre_tipo = tipocapacitacion.Nombre;
                this.listaCapacitaciones.push({ ...transformado});  
            },
            resetModalCapacitacion() {
                let me = this;
                me.datosCapacitacion.nombre             = "",
                me.datosCapacitacion.descripcion        = "",
                me.datosCapacitacion.urlArchivo         = "",
                me.datosCapacitacion.archivoMeta        = null,
                me.datosCapacitacion.documento          = null,
                me.datosCapacitacion.idTipoCapacitacion = null
            },
            resetModalCategoria() {
                let me = this
                me.datosCategoria.nombre = ""
            },
            RegistrarCategoria() {
                let me = this
                let registrar = [];
                registrar.push({
                    categoria: me.datosCategoria
                });
    
                axios.post(
                        CONSTANTES.URL_RUTA_SERVICIOS + "administrador/registrar-categoria",
                        registrar, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`
                            },
                        }
                    )
                    .then(function (response) {
                        let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                        let color = response.data.resultado == 1 ? "success" : "danger";
    
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: color,
                            icon: iconn,
                            message: response.data.mensaje
                        });
                        me.resetModalCategoria();
                        me.ObtenerTipoCapacitacion();
    
                    })
                    .catch(function (error) {
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: 'Error, algo salió mal.'
                        });
                    });
            },
    
            AnularCapacitacion(param) {
                let me = this
                let NombreCapacitacion = param.item.nombre;
                me.$bvModal
                    .msgBoxConfirm(
                        "Esta seguro que desea eliminar la capacitación " +
                        NombreCapacitacion +
                        " ? ", {
                            title: "Por favor, confirmar",
                            size: "sm",
                            buttonSize: "sm",
                            okVariant: "success",
                            okTitle: "SI",
                            cancelTitle: "NO",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        }
                    )
                    .then((r) => {
                        if (r == true) {
                            axios
                                .post(
                                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/cambiar-estado-capacitacion", {
                                        estado: "1",
                                        idCapacitacion: param.item.idCapacitacion
                                    }, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.token}`
                                        },
                                    }
                                ).then((response) => {
                                    if (response) {
                                        me.ListarCapacitaciones();
                                    }
                                })
                                .catch(function (error) {
                                    me.$notify({
                                        verticalAlign: 'bottom',
                                        horizontalAlign: 'right',
                                        type: 'danger',
                                        icon: 'emoji-frown',
                                        message: 'Error, algo salió mal.'
                                    });
                                });
                        }
                    });
            },
    
            RestaurarCapacitacion(param) {
                let me = this
                let NombreCapacitacion = param.item.nombre;
                me.$bvModal
                    .msgBoxConfirm(
                        "Esta seguro que desea restaurar la capacitación " +
                        NombreCapacitacion +
                        " ? ", {
                            title: "Por favor, confirmar",
                            size: "sm",
                            buttonSize: "sm",
                            okVariant: "success",
                            okTitle: "SI",
                            cancelTitle: "NO",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        }
                    )
                    .then((r) => {
                        if (r == true) {
                            axios
                                .post(
                                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/cambiar-estado-capacitacion", {
                                        estado: "2",
                                        idCapacitacion: param.item.idCapacitacion
                                    }, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.token}`
                                        },
                                    }
                                ).then((response) => {
                                    if (response) {
                                        me.ListarCapacitaciones();
                                    }
                                })
                                .catch(function (error) {
                                    me.$notify({
                                        verticalAlign: 'bottom',
                                        horizontalAlign: 'right',
                                        type: 'danger',
                                        icon: 'emoji-frown',
                                        message: 'Error, algo salió mal.'
                                    });
                                });
                        }
                    });
            },
    
        },
        mounted() {
            if (localStorage.usuario) {
                let me = this
                me.ObtenerTipoCapacitacion();
                me.ListarCapacitaciones();
            }
        }
    };
    </script>
    