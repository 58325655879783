<template>
<div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12" class="mb-5">
                <b-overlay :show="cargaAdministrador" rounded="lg" opacity="0.5" spinner-variant="default">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <card header-bg-variant="info" header-classes="bg-transparent">
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Registrar Administrador</h3>
                            </b-col>
                        </b-row>
                        <!--    <h6 class="heading-small text-muted mb-4">User information</h6>-->
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(registraAdministrador)">
                                <div class="pl-lg-3 pr-lg-3">
                                    <b-row>
                                        <b-col lg="4">
                                            <validation-provider name="de tipo de documento" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Tipo de Documento" class="labelx">
                                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" id="tipoDoc" v-model="datosPersona.tipodocumento" :options="comboTipoDocumento" size="sm" value-field="idTipoDocumento" text-field="Descripcion">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback id="input-1-live-feedback">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col lg="4">
                                            <base-input label="Número de documento" name="de número de documento" :rules="{ required: true,min:8,max:14,numeric:true }">
                                                <b-overlay :show="iniciaCarga" opacity="0.5" spinner-small>
                                                    <b-input-group>
                                                        <b-form-input autocomplete="off" v-model="datosPersona.numeroDocumento" size="sm" placeholder="Ingrese su documento"></b-form-input>
                                                        <b-input-group-append>
                                                            <base-button size="sm" type="default" @click="apiDNI" :disabled="buscarDniBtn" >
                                                                <b-icon icon="search" aria-label="Help"></b-icon>
                                                            </base-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-overlay>
                                                
                                            </base-input>
                                         
                                        </b-col>
                                        <b-col lg="4">
                                            <base-input label="Nombres" name="de nombres" :rules="{ required: true }" placeholder="Ingrese nombres" v-model="datosPersona.nombres" input-classes="form-control-sm" class="mb-1" :disabled="iniApiDNI">
                                            </base-input>
                                        </b-col>
                                        <b-col lg="4">
                                            <base-input label="Apellido paterno" name="de apellido paterno" :rules="{ required: true }" placeholder="Ingrese Apellido paterno" v-model="datosPersona.apellidoPaterno" input-classes="form-control-sm" :disabled="iniApiDNI">
                                            </base-input>
                                        </b-col>
                                        <b-col lg="4">
                                            <base-input label="Apellido materno" name="de apellido materno" :rules="{ required: true }" placeholder="Ingrese Apellido materno" v-model="datosPersona.apellidoMaterno" input-classes="form-control-sm" :disabled="iniApiDNI">
                                            </base-input>
                                        </b-col>
                                        <b-col lg="4">
                                            <base-input label="Correo" name="de correo" :rules="{ required: true, email: true }" placeholder="Ingrese correo" v-model="datosPersona.correo" input-classes="form-control-sm" :disabled="iniApiDNI">
                                            </base-input>
                                        </b-col>
                                        <b-col lg="4">
                                            <base-input label="Fecha de Nacimiento" name="de fecha de nacimiento" :rules="{ required: true }" type="date" v-model="datosPersona.fechaNacimiento" input-classes="form-control-sm" :disabled="iniApiDNI">
                                            </base-input>
                                        </b-col>
                                        <b-col lg="4">
                                            <validation-provider name="de estado civil" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Estado Civil" class="labelx">
                                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" size="sm" :disabled="iniApiDNI" :options="comboEstadoCivil" value-field="idEstadoCivil" text-field="Descripcion" v-model="datosPersona.estadoCivil">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col lg="4">
                                            <validation-provider name="de sexo" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group class="labelx" label="Sexo">
                                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="datosPersona.sexo" :options="comboSexo" size="sm" :disabled="iniApiDNI">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col lg="12 text-right">
                                            <b-button size="sm" variant="success" class="mb-2" type="submit">
                                                Registrar Administrador
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-form>
                        </validation-observer>
                    </card>
                </b-overlay>
            </b-col>
        </b-row>
    </base-header>

    <!--formulario-->
    <b-container fluid class="mt--8 mb-5">
        <b-row>
            <b-col xl="12">
                <b-overlay :show="cargaAdministrador" rounded="lg" opacity="0.5" spinner-variant="default">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <card header-bg-variant="info" header-classes="bg-transparent">
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Administradores de capacitación</h3>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="4">
                                <base-input v-if="!cargaAdministrador">
                                    <b-input-group class="text-right" size="xl">
                                        <b-input-group-prepend is-text>
                                            <b-icon icon="search"></b-icon>
                                        </b-input-group-prepend>
                                        <b-form-input autocomplete="off" type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                        <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                    </b-input-group>
                                </base-input>
                            </b-col>
                            <b-col lg="4">
                            </b-col>
                            <b-col lg="4">
                                <base-input v-if="!cargaAdministrador">
                                    <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                </base-input>
                            </b-col>
                            <b-col lg="12">
                                <b-table sort-icon-left show-empty small responsive outlined :items="administradores" :fields="camposAdministracion" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay Administradores en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(actions)="param">
                                        <template v-if="param.item.estado == 2">
                                            <base-button icon size="sm" type="danger" @click="AnularAdministrador(param)">
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text">Anular</span>
                                            </base-button>
                                        </template>
                                        <template v-else>
                                            <base-button icon size="sm" type="success" @click="RestaurarAdministrador(param)">
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="arrow-counterclockwise" aria-label="Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text">Restaurar</span>
                                            </base-button>
                                        </template>

                                        <base-button icon size="sm" type="default" @click="CargarDatosModificables(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Modificar</span>
                                        </base-button>
                                    </template>
                                    <template v-slot:cell(estado)="data">
                                        <b class="text-danger" v-if="data.value == '1'">INACTVO</b>
                                        <b class="text-success" v-else>ACTIVO</b>
                                    </template>
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <template v-slot:footer>
                            <b-row v-if="!cargaAdministrador">
                                <b-col lg="12">
                                    <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-overlay>
            </b-col>
        </b-row>
    </b-container>
    <!--End formulario-->
    <b-modal id="modal-modificar-administrador" ref="modal-modificar-administrador" title=" Modificar datos del administrador" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetFormAdministrador">
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(ActualizarAdministrador)">
                <div class="pl-lg-3 pr-lg-3">
                    <b-row>
                        <b-col lg="6">
                            <base-input label="Tipo de Documento" name="de tipo de documento " :rules="{ required: true }" placeholder="Ingrese tipo de documento" v-model="modifPersona.Desctipodocumento" input-classes="form-control-sm" disabled>
                            </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Número de Documento" name="de numero de documento " :rules="{ required: true }" placeholder="Ingrese numero de documento" v-model="modifPersona.numeroDocumento" input-classes="form-control-sm" disabled>
                            </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Apellido Paterno" name="de apellido paterno " :rules="{ required: true }" placeholder="Ingrese apellido paterno" v-model="modifPersona.apellidoPaterno" input-classes="form-control-sm">
                            </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Apellido Materno" name="de apellido materno " :rules="{ required: true }" placeholder="Ingrese apellido Materno" v-model="modifPersona.apellidoMaterno" input-classes="form-control-sm">
                            </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Nombres" name="de nombres " :rules="{ required: true }" placeholder="Ingrese nombres" v-model="modifPersona.nombres" input-classes="form-control-sm">
                            </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Correo" name="de correo " :rules="{ required: true, email: true }" placeholder="Ingrese correo" v-model="modifPersona.correo" input-classes="form-control-sm">
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Fecha de Nacimiento" name="de fecha de nacimiento " :rules="{ required: true }" type="date" placeholder="Ingrese Fecha de Nacimiento" v-model="modifPersona.fechaNacimiento" input-classes="form-control-sm">
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                            <validation-provider name="de estado civil " :rules="{ required: true }" v-slot="validationContext">
                                <b-form-group label="Estado Civil" class="labelx">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="modifPersona.estadoCivil" :options="comboEstadoCivil" size="sm" value-field="idEstadoCivil" text-field="Descripcion">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="4">
                            <validation-provider name="de sexo " :rules="{ required: true }" v-slot="validationContext">
                                <b-form-group label="Sexo" class="labelx">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="modifPersona.sexo" :options="comboSexo" size="sm">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-invalid-feedback id="input-1-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                            </validation-provider>
                        </b-col>

                        <b-col lg="12 text-right">
                            <b-button variant="success" size="sm" type="submit">Actualizar</b-button>
                            <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-modificar-administrador')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {},
    data() {
        return {
            buscarDniBtn: true,
            cargaAdministrador: false,
            administradores: [],
            comboEstadoCivil: [],
            comboTipoDocumento: [],
            datosPersona: {
                tipodocumento: null,
                estadoCivil: null,
                sexo: null,
                numeroDocumento: "",
                tipousuario: "2",
                apellidoPaterno: "",
                apellidoMaterno: "",
                nombres: "",
                correo: "",
                fechaNacimiento: "",
                idCliente: "0",
                usuario: "admin",
            },
            modifPersona: {
                tipodocumento: null,
                Desctipodocumento: null,
                estadoCivil: null,
                estado: null,
                sexo: null,
                numeroDocumento: "",
                apellidoPaterno: "",
                apellidoMaterno: "",
                nombres: "",
                correo: "",
                fechaNacimiento: "",
                idPersona: "",
                nombreCorto: "",
                estadoResponsable: "",
                usuario: "admin",
            },
            comboSexo: [{
                    value: "M",
                    text: "Masculino",
                },
                {
                    value: "F",
                    text: "Femenino",
                },
            ],
            camposAdministracion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "descTipoDoc",
                    label: "Tipo de documento",
                    class: "text-center",
                },
                {
                    key: "numeroDocumento",
                    label: "Numero de documento",
                    class: "text-center",
                },
                {
                    key: "shortName",
                    label: "Nombre completo",
                    class: "text-center",
                },
                {
                    key: "FechaNacimiento",
                    label: "Fecha de Nacimiento",
                    class: "text-center",
                },
                {
                    key: "correo",
                    label: "Correo",
                    class: "text-center",
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "actions",
                    label: "opciones",
                    class: "text-center",
                },
            ],
            iniApiDNI: true,
            iniciaCarga: false,

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],

            filter: null,
            filterOn: [],
        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        CargarDatosModificables(param) {
            let me = this;
            me.modifPersona.idPersona = param.item.idPersona;
            me.modifPersona.tipodocumento = param.item.idTipoDocumento;
            me.modifPersona.Desctipodocumento = param.item.descTipoDoc;
            me.modifPersona.estadoCivil = param.item.idEstadoCivil;
            me.modifPersona.sexo = param.item.Sexo;
            me.modifPersona.numeroDocumento = param.item.numeroDocumento;
            me.modifPersona.apellidoPaterno = param.item.ApellidoPaterno;
            me.modifPersona.apellidoMaterno = param.item.ApellidoMaterno;
            me.modifPersona.nombres = param.item.Nombres;
            me.modifPersona.correo = param.item.correo;
            me.modifPersona.fechaNacimiento = param.item.FechaNacimiento;
            me.modifPersona.nombreCorto = param.item.shortName;
            me.modifPersona.idCliente = "0";
            me.$refs["modal-modificar-administrador"].show();
        },
        obtenerEstadoCivil() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-estado-civil")
                .then(function (response) {
                    me.comboEstadoCivil = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: "bottom",
                        horizontalAlign: "right",
                        type: "danger",
                        icon: "emoji-frown",
                        message: "Error, algo salió mal.",
                    });
                });
        },
        obtenerTipoDocumento() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-tipo-documento")
                .then(function (response) {
                    me.comboTipoDocumento = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: "bottom",
                        horizontalAlign: "right",
                        type: "danger",
                        icon: "emoji-frown",
                        message: "Error, algo salió mal.",
                    });
                });
        },
        ActualizarAdministrador() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/actualizar-responsable",
                    me.modifPersona, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`,
                        },
                    }
                )
                .then(function (response) {

                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.ListarAdministradores();
                    me.$refs["modal-modificar-administrador"].hide();

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: "bottom",
                        horizontalAlign: "right",
                        type: "danger",
                        icon: "emoji-frown",
                        message: "Error, algo salió mal.",
                    });
                });
        },
        registraAdministrador() {
            let me = this;
            me.cargaAdministrador = true;
            let registrarAdministrador = [];
            registrarAdministrador.push({
                persona: me.datosPersona,
            });

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-administrador",
                    registrarAdministrador, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`,
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.ListarAdministradores();
                    me.cargaAdministrador = false;
                    me.resetFormAdministrador();
                    me.iniApiDNI = true;
                    me.$nextTick(() => {
                        me.$refs.observer.reset();
                    });

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: "bottom",
                        horizontalAlign: "right",
                        type: "danger",
                        icon: "emoji-frown",
                        message: "Error, algo salió mal.",
                    });
                });
        },
        AnularAdministrador(param) {
            let me = this;
            let ENombreCompleto = param.item.shortName;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea eliminar al administrador " +
                    ENombreCompleto +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "master/desactivar-usuario", {
                                    IdPersona: param.item.idPersona,
                                    Estado: "1",
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then((response) => {
                                if (response) {
                                    me.ListarAdministradores();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: "bottom",
                                    horizontalAlign: "right",
                                    type: "danger",
                                    icon: "emoji-frown",
                                    message: "Error, algo salió mal.",
                                });
                            });
                    }
                });
        },
        RestaurarAdministrador(param) {
            let me = this;
            let ENombreCompleto = param.item.shortName;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea restaurar al administrador " +
                    ENombreCompleto +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "master/desactivar-usuario", {
                                    IdPersona: param.item.idPersona,
                                    Estado: "2",
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then((response) => {
                                if (response) {
                                    me.ListarAdministradores();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: "bottom",
                                    horizontalAlign: "right",
                                    type: "danger",
                                    icon: "emoji-frown",
                                    message: "Error, algo salió mal.",
                                });
                            });
                    }
                });
        },
        resetFormAdministrador() {
            let me = this;
            me.datosPersona.tipodocumento = null;
            me.datosPersona.estadoCivil = null;
            me.datosPersona.sexo = null;
            me.datosPersona.numeroDocumento = "";
            me.datosPersona.apellidoPaterno = "";
            me.datosPersona.apellidoMaterno = "";
            me.datosPersona.nombres = "";
            me.datosPersona.correo = "";
            me.datosPersona.fechaNacimiento = "";
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        apiDNI() {
            let me = this;
            let mi_token =
                "76afdc4b32381ff72ff7a4d827f7856bb850e6228adc9e9f6f1c74e114510f22";
            me.iniciaCarga = true;
            axios
                .get("https://apiperu.dev/api/dni/" + me.datosPersona.numeroDocumento, {
                    headers: {
                        Authorization: `Bearer ${mi_token}`,
                    },
                    responseType: "json",
                })
                .then((response) => {
                    if (response.data.data) {
                        me.datosPersona.nombres = response.data.data.nombres;
                        me.datosPersona.apellidoPaterno =
                            response.data.data.apellido_paterno;
                        me.datosPersona.apellidoMaterno =
                            response.data.data.apellido_materno;
                    }
                    else {
                           me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: response.data.message
                        });
                    }
                    me.iniApiDNI = false;
                    me.iniciaCarga = false;
                })
                .catch((error) => {
                    me.$notify({
                        verticalAlign: "bottom",
                        horizontalAlign: "right",
                        type: "danger",
                        icon: "emoji-frown",
                        message: "Error, algo salió mal.",
                    });
                   
                });
        },
        ListarAdministradores() {
            let me = this;
            me.cargaAdministrador = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "master/listar-administradores-capacitacion"
                )
                .then(function (response) {
                    me.administradores = response.data;
                    me.filasTotales = me.administradores.lenght
                    me.cargaAdministrador = false;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: "bottom",
                        horizontalAlign: "right",
                        type: "danger",
                        icon: "emoji-frown",
                        message: "Error, algo salió mal.",
                    });
                });
        },
    },
    watch: {
          'datosPersona.numeroDocumento': function (val) {
           if (val.length >= 8 && val.length <= 14){
               this.buscarDniBtn = false
           }
           else{
               this.buscarDniBtn = true
           }
        }
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this;
            me.obtenerEstadoCivil();
            me.obtenerTipoDocumento();
            me.ListarAdministradores();
        }
    },
};
</script>
