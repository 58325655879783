<!--
Agregar Trabajador
mdoficar
-asingarle capacitaciones
-->
<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12">
                    <b-overlay :show="cargarTrabajador" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent">
                            <template v-slot:header>
                                <b-row align-v="center" slot="header">
                                    <b-col>
                                        <h3 class="mb-0">Trabajadores</h3>
                                    </b-col>
                                    <b-col class="text-right">
                                        <template v-if="listaTrabajadores.length >= cantidadTrabajadoresPermitidos && render == 1">
                                            <span class="btn btn-sm btn-default">
                                                <b-icon icon="eject-fill" aria-hidden="true"></b-icon> Limite de trabajadores alcanzado
                                            </span>
                                        </template>
                                        <template v-else-if="listaTrabajadores.length < cantidadTrabajadoresPermitidos && render == 1">
                                            <span class="btn btn-sm btn-primary" v-b-modal.modal-registro-trabajador>
                                                <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar
                                            </span>
                                        </template>
                                    </b-col>

                                </b-row>
                            </template>
                            <b-row>
                                <b-col lg="4">
                                    <base-input v-if="cargarTrabajador==false">
                                        <b-input-group class="text-right" size="xl">
                                            <b-input-group-prepend is-text>
                                                <b-icon icon="search"></b-icon>
                                            </b-input-group-prepend>
                                            <b-form-input autocomplete="off" type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                            <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                        </b-input-group>
                                    </base-input>
                                </b-col>
                                <b-col lg="4">

                                </b-col>
                                <b-col lg="4">
                                    <base-input v-if="cargarTrabajador==false">
                                        <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                    </base-input>
                                </b-col>
                                <b-col lg="12">
                                    <b-table sort-icon-left show-empty small responsive outlined :items="listaTrabajadores" :fields="camposTrabajadores" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                        <template v-slot:cell(actions)="param">
                                            <base-button icon size="sm" type="default" @click="CargarDatosModificables(param)">
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text">Modificar</span>
                                            </base-button>
                                            <base-button icon size="sm" type="danger" @click="eliminarTrabajador(param)">
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="trash" aria-label="Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text">Eliminar</span>
                                            </base-button>
                                        </template>
                                        <template v-slot:cell(estado)="data">
                                            <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                                            <b class="text-success" v-else>ACTIVO</b>
                                        </template>
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    </b-table>
                                </b-col>
                            </b-row>
                            <template v-slot:footer>
                                <b-row v-if="cargarTrabajador==false">
                                    <b-col lg="12">
                                        <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
    </b-container>
    <!--End container-->
    <b-modal class="bg-secondary" id="modal-registro-trabajador" ref="modal-registro-trabajador" title="Registrar datos del Trabajador" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetFormTrabajador">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(RegistrarTrabajador)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="4">
                            <base-input label="Tipo de Documento" name="de tipo de documento" :rules="{ required: true }">
                                <b-form-select id="tipoDoc" v-model="datosPersona.tipodocumento" :options="comboTipoDocumento" size="sm" value-field="idTipoDocumento" text-field="Descripcion">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Numero de Documento" name="numero de documento" :rules="{ required: true,min:8,max:14,numeric:true }">
                                <b-overlay :show="iniciaCarga" opacity="0.5" spinner-small>
                                    <b-input-group>
                                        <b-form-input autocomplete="off" v-model="datosPersona.numeroDocumento" size="sm" placeholder="Ingrese su documento"></b-form-input>
                                        <b-input-group-append>
                                            <base-button size="sm" type="default" @click="apiDNI" :disabled="buscarDniBtn">
                                                <b-icon icon="search" aria-label="Help"></b-icon>
                                            </base-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-overlay>
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Nombres" name="de nombres" :rules="{ required: true }" placeholder="Ingrese nombres" v-model="datosPersona.nombres" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Apellido paterno" name="de apellido paterno" :rules="{ required: true }" placeholder="Ingrese Apellido paterno" v-model="datosPersona.apellidoPaterno" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Apellido materno" name="de apellido materno" :rules="{ required: true }" placeholder="Ingrese Apellido materno" v-model="datosPersona.apellidoMaterno" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Correo" name="de correo" :rules="{ required: true,email:true }" placeholder="Ingrese correo" v-model="datosPersona.correo" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Fecha de Nacimiento" name="de fecha de nacimiento" :rules="{ required: true}" type="date" v-model="datosPersona.fechaNacimiento" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Estado Civil" name="de estado civil" :rules="{ required: true }">
                                <b-form-select size="sm" :options="comboEstadoCivil" value-field="idEstadoCivil" text-field="Descripcion" v-model="datosPersona.estadoCivil">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Sexo" name="de sexo" :rules="{ required: true }">
                                <b-form-select v-model="datosPersona.sexo" :options="comboSexo" size="sm">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </base-input>
                        </b-col>
                        <b-col lg="12 text-right">
                            <b-button variant="success" size="sm" type="submit">Registrar</b-button>
                            <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-registro-trabajador')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>

    <b-modal class="bg-secondary" id="modal-actualizar-trabajador" ref="modal-actualizar-trabajador" title="Actualizar datos del Trabajador" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetFormTrabajador">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(ActualizarTrabajador)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="4">
                            <base-input label="Tipo de documento" name="de tipo de documento" :rules="{ required: true }" placeholder="Ingrese tipo numero de documento" v-model="modifPersona.tipodocumento" input-classes="form-control-sm" class="mb-1" disabled> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Numero de documento" name="de numero de documento" :rules="{ required: true }" placeholder="Ingrese numero de documento" v-model="modifPersona.numeroDocumento" input-classes="form-control-sm" class="mb-1" disabled> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Nombres" name="de nombres" :rules="{ required: true }" placeholder="Ingrese nombres" v-model="modifPersona.nombres" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Apellido paterno" name="de apellido paterno" :rules="{ required: true }" placeholder="Ingrese Apellido paterno" v-model="modifPersona.apellidoPaterno" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Apellido materno" name="de apellido materno" :rules="{ required: true }" placeholder="Ingrese Apellido materno" v-model="modifPersona.apellidoMaterno" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Correo" name="de correo" :rules="{ required: true,email:true }" placeholder="Ingrese correo" v-model="modifPersona.correo" input-classes="form-control-sm" disabled> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Fecha de Nacimiento" name="de fecha de nacimiento" :rules="{ required: true}" type="date" v-model="modifPersona.fechaNacimiento" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Estado Civil" name="de Estado Civil" :rules="{ required: true }">
                                <b-form-select id="estadocivil" :options="comboEstadoCivil" v-model="modifPersona.estadoCivil" size="sm" value-field="idEstadoCivil" text-field="Descripcion">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>- Estado Civil -</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Sexo" name="de sexo" :rules="{ required: true }">
                                <b-form-select v-model="modifPersona.sexo" :options="comboSexo" size="sm">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </base-input>
                        </b-col>
                        <b-col lg="12 text-right">
                            <b-button variant="success" size="sm" type="submit">Actualizar</b-button>
                            <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-actualizar-trabajador')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { sweetQuery } from '@/util/sweetAlert.js';
import { Form } from "element-ui";

export default {
    name: "ControlTrabajadores",
    components: {

    },
    data() {
        return {
            buscarDniBtn: true,
            render: "",
            cargarTrabajador: true,
            cantidadTrabajadoresPermitidos: "",

            comboSexo: [{
                    value: "M",
                    text: "Masculino"
                },
                {
                    value: "F",
                    text: "Femenino"
                },
            ],
            comboEstadoCivil: [],
            comboTipoDocumento: [],
            listaTrabajadores: [],
            datosCliente: {
                razonSocial: "",
            },

            datosPersona: {
                tipodocumento: null,
                estadoCivil: null,
                sexo: null,
                numeroDocumento: "",
                tipousuario: "4",
                apellidoPaterno: "",
                apellidoMaterno: "",
                nombres: "",
                correo: "",
                fechaNacimiento: "",
                idCliente: "",
                usuario: "",
            },
            modifPersona: {
                tipodocumento: null,
                estadoCivil: null,
                sexo: null,
                numeroDocumento: "",
                apellidoPaterno: "",
                apellidoMaterno: "",
                idCliente: "",
                nombres: "",
                correo: "",
                fechaNacimiento: "",
                idPersona: "",
                usuario: "",
            },
            camposTrabajadores: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "descTipoDoc",
                    label: "Tipo de documento",
                    class: "text-center "
                },
                {
                    key: "numeroDocumento",
                    label: "Número de documento",
                    class: "text-center"
                },
                {
                    key: "shortName",
                    label: "Nombre completo",
                    class: "text-center"
                },
                {
                    key: "FechaNacimiento",
                    label: "Fecha de Nacimiento",
                    class: "text-center"
                },
                {
                    key: "correo",
                    label: "Correo",
                    class: "text-center"
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center"
                },
                {
                    key: "actions",
                    label: "Opciones",
                    class: "text-center"
                },
            ],
            iniciaCarga: false,

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],
            filter: null,
            filterOn: [],
        }
    },
    methods: {
        eliminarTrabajador(param) {
            const row       = param.item;
            const formData  = new FormData();
            formData.append('trabajadorData', JSON.stringify(row));
            sweetQuery('Eliminar',`¿Desea eliminar al trabajador ${row.Nombres} ${row.ApellidoPaterno} ${row.ApellidoMaterno} ?`,'question','responsable/eliminar-trabajador',formData,'POST').then(respuesta => {
                if(respuesta.success){
                    this.ListarTrabajadores();
                }
            })  
        },
        resetFormTrabajador() {
            let me = this;
            me.datosPersona.tipodocumento = null
            me.datosPersona.estadoCivil = null
            me.datosPersona.sexo = null
            me.datosPersona.numeroDocumento = ""
            me.datosPersona.apellidoPaterno = ""
            me.datosPersona.apellidoMaterno = ""
            me.datosPersona.nombres = ""
            me.datosPersona.correo = ""
            me.datosPersona.fechaNacimiento = ""
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },

        apiDNI() {
            let me = this;
            let mi_token =
                "76afdc4b32381ff72ff7a4d827f7856bb850e6228adc9e9f6f1c74e114510f22";
            me.iniciaCarga = true;
            axios
                .get("https://apiperu.dev/api/dni/" + me.datosPersona.numeroDocumento, {
                    headers: {
                        Authorization: `Bearer ${mi_token}`
                    },
                    responseType: "json",
                })
                .then((response) => {
                    if (response.data.data) {
                        me.datosPersona.nombres = response.data.data.nombres;
                        me.datosPersona.apellidoPaterno = response.data.data.apellido_paterno;
                        me.datosPersona.apellidoMaterno = response.data.data.apellido_materno;
                    } else {
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: response.data.message
                        });

                    }
                    me.iniciaCarga = false;
                })
                .catch((error) => {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        obtenerEstadoCivil() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-estado-civil")
                .then(function (response) {
                    me.comboEstadoCivil = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        obtenerTipoDocumento() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-tipo-documento")
                .then(function (response) {
                    me.comboTipoDocumento = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        CargarDatosModificables(param) {
            let me = this
            me.modifPersona.idPersona       = param.item.idPersona;
            me.modifPersona.tipodocumento   = param.item.descTipoDoc;
            me.modifPersona.estadoCivil     = param.item.idEstadoCivil;
            me.modifPersona.sexo            = param.item.Sexo;
            me.modifPersona.numeroDocumento = param.item.numeroDocumento;
            me.modifPersona.apellidoPaterno = param.item.ApellidoPaterno;
            me.modifPersona.apellidoMaterno = param.item.ApellidoMaterno;
            me.modifPersona.nombres         = param.item.Nombres;
            me.modifPersona.correo          = param.item.correo;
            me.modifPersona.fechaNacimiento = param.item.FechaNacimiento;
            me.modifPersona.idCliente       = me.datosPersona.idCliente;
            me.modifPersona.usuario         = me.datosPersona.usuario;
            me.$refs["modal-actualizar-trabajador"].show();
        },
        RegistrarTrabajador() {
            let me = this;
            let registrarTrabajador = [];
            registrarTrabajador.push({
                persona: me.datosPersona
            });

            axios.post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/registrar-trabajador",
                    registrarTrabajador, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
            ).then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.ListarTrabajadores();
                    me.$refs["modal-registro-trabajador"].hide();

            }).catch(function (error) {
                me.$notify({
                    verticalAlign: 'bottom',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Error, algo salió mal.'
                });
            });
        },
        ActualizarTrabajador() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/actualizar-trabajador",
                    me.modifPersona, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.ListarTrabajadores();
                    me.$refs["modal-actualizar-trabajador"].hide();

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarTrabajadores() {
            let me = this;
            me.cargarTrabajador = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "responsable/listar-trabajadores", {
                        params: {
                            idCliente: me.datosPersona.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.filasTotales = response.data.length
                    me.listaTrabajadores = response.data
                    me.cargarTrabajador = false
                    me.render = 1
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        decryptData(encryptedData) {
            try{
                const SECRET_KEY    = process.env.VUE_APP_SECRET_KEY;
                const bytes         = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
                const decrypt       = bytes.toString(CryptoJS.enc.Utf8);
                return decrypt;
            }catch(error){
                console.log(error);
            }
        },
    },
    watch: {
  'datosPersona.numeroDocumento': function (val) {
           if (val.length >= 8 && val.length <= 14){
               this.buscarDniBtn = false
           }
           else{
               this.buscarDniBtn = true
           }
        }
    },
    mounted() {
        if (localStorage.usuario) {
            let me          = this
            const decrypt   = me.decryptData(localStorage.usuario);
            const lsUsuario = JSON.parse(decrypt); 
            me.datosCliente.razonSocial         = lsUsuario.razonSocial
            me.datosPersona.idCliente           = lsUsuario.nIdCliente
            me.datosPersona.usuario             = lsUsuario.username
            me.cantidadTrabajadoresPermitidos   = lsUsuario.cantidad
            me.obtenerEstadoCivil();
            me.obtenerTipoDocumento();
            me.ListarTrabajadores();
        }
    }
};
</script>
