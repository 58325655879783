<!--
*Ver capacitacion
*Ver archivos
*Ver evaluaciones
-->
<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12" class="order-xl-2 ">
                <b-overlay :show="cargarInfoCapacitacion" rounded="lg" opacity="0.5">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-card-body>
                            <b-row>
                                <b-col lg="5 text-center" style="align-self: center;">
                                    <template>
                                        
                                        <div>
                                           <b-img class=" img-responsive img-fluid" :alt="datosCapacitacion.nombreCapacitacion" center rounded width="400px" height="240px" :src="datosCapacitacion.urlPortada" />

                                        <!--    <b-img-lazy center fluidGrow blank="true" blankColor="#bbb" :src="datosCapacitacion.urlPortada" rounded :alt="datosCapacitacion.nombreCapacitacion"></b-img-lazy>-->
                                        </div>
                                    </template>
                                </b-col>
                                <b-col lg="7">
                                    <div class="text-left">
                                        <div class="h4 mt-4">
                                            {{datosCapacitacion.nombre}}
                                        </div>

                                        <div class="h5 font-weight-300" align="justify">
                                            {{datosCapacitacion.descripcion}}
                                        </div>
                                        <hr class="my-3">
                                        <div class="h4 font-weight-300">
                                            <b-badge class="defaultx">{{ datosCapacitacion.nombre_tipo }}</b-badge>
                                        </div>

                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-overlay>
            </b-col>
        </b-row>
    </base-header>
    <!--End head-->

    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12 ">
                    <template v-if="!isLoading">
                        <CanvasCapacitacion 
                            :isTrabajador       = true
                            :datosCapacitacion  = "datosCapacitacion"
                            @actualizar-proceso = "CargarProcesoActualizado"
                        />
                    </template>
                    <template v-else>
                        <div class="text-center">
                            <div class="loader">Cargando...</div>
                        </div>
                    </template>
                </b-col>
            
                <b-col xl="12">
                    <b-overlay :show="cargarEvaluacion" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent" class="mt-4">
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h5 class="h3 mb-0">Evaluaciones</h5>
                                </b-col>
                            </b-row>
                            <b-table v-if="datosCapacitacion.proceso == 3" sort-icon-left show-empty small responsive outlined :items="listaEvaluaciones" :fields="camposEvaluacion" :current-page="paginaActualEva" :per-page="porPaginaEva" empty-text="Aún no hay evaluaciones en esta sección.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <!--     <router-link :to="{name:'nueva evaluacion', params: {id: param.item.id_evaluacion, nombre: param.item.nombre}}" class="text-light">-->
                                    <base-button icon size="sm" type="default" @click="AbrirParam(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="check2-all" aria-label="Help"></b-icon>
                                        </span>
                                        Seleccionar evaluación
                                    </base-button>
                                </template>
                            </b-table>
                            <h4 v-else class="text-muted text-center">Primero debes terminar la capacitación </h4>

                            <template v-slot:footer>
                                <b-row v-if="!cargarEvaluacion">
                                    <b-col lg="12">
                                        <b-pagination size="sm" style="justify-content:flex-end;" v-model="paginaActualEva" :total-rows="filasTotalesEva" :per-page="porPaginaEva" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>

                <b-col xl="12">
                    <b-overlay :show="cargandoArchivos" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent" class="mt-4">
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h5 class="h3 mb-0">Archivos</h5>
                                </b-col>
                            </b-row>
                            <b-table sort-icon-left show-empty small responsive outlined :items="listaArchivos" :fields="campoArchivos" :current-page="paginaActualArc" :per-page="porPaginaArc" empty-text="Aún no hay archivos en esta sección." :busy="cargandoArchivos">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <base-button icon size="sm" type="success" @click="descargarArchivo(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="cloud-download" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Descargar </span>
                                    </base-button>

                                </template>
                                <template v-slot:table-busy>
                                    <div style="  color: #525f7f !important; " class="text-center text-secondary my-2">
                                        <b-spinner class="align-middle" small></b-spinner>
                                        <strong> Cargando ...</strong>
                                    </div>
                                </template>
                            </b-table>
                            <template v-slot:footer>
                                <b-row v-if="!cargandoArchivos">
                                    <b-col lg="12">
                                        <b-pagination size="sm" style="justify-content:flex-end;" v-model="paginaActualArc" :total-rows="filasTotalesArc" :per-page="porPaginaArc" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>

            </b-row>
        </div>
        <!--End tables-->
    </b-container>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import CryptoJS from 'crypto-js';
import CanvasCapacitacion from "../../components/Canvas/CanvasCapacitacion.vue";

export default {
    name: "MantenimientoCapacitacion",
    components: {
        CanvasCapacitacion
    },
    data() {
        return {
            isUltimoDeck            : "1",
            isEmpezado              : "",
            isUltimo                : "",
            ArchivoCapacitacion     : null,
            idCliente               : "",
            idPersona               : "",
            isError                 : true,
            isLoading               : false,
            cargarInfoCapacitacion  : true,
            cargandoArchivos        : true,
            cargarEvaluacion        : false,
            cargarPreguntas         : false,
            emitirAlerta            : false,
            mensajeAlerta           : "",
            usuario                 : "",
            pageNum                 : 1,
            numPages                : 0,
            isFullScreen            : false,

            listaArchivos: [],
            listaEvaluaciones: [],

            datosCapacitacion: {
                idCapacitacion: "",
                idPersonaCapacitacion: "",
                nombre: "",
                descripcion: "",
                urlArchivo: "",
                urlPortada: "",
                nombre_tipo: "",
                proceso: "",
                documento : null,
            },
            datosParam: {
                idPersonaCapacitacion: "",
            },
            campoArchivos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombreArchivo",
                    label: "Nombre",
                    class: "text-center"
                },
                {
                    key: "mimetype",
                    label: "Tipo",
                    class: "text-center"
                },
                {
                    key: "size",
                    label: "Tamaño",
                    class: "text-center"
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    class: "text-center"
                },
            ],
            camposEvaluacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center"
                },
                {
                    key: "n_preguntas",
                    label: "N° Preguntas",
                    class: "text-center"
                },

                {
                    key: "opciones",
                    label: "opciones",
                    class: "text-center"
                },
            ],

            filasTotalesEva: 1,
            paginaActualEva: 1,
            porPaginaEva: 5,
            filasTotalesArc: 1,
            paginaActualArc: 1,
            porPaginaArc: 5,
        };
    },
    methods: {
        cargarDatosCapacitacionTrabajador() {
            let me = this;
            me.cargarInfoCapacitacion = true,
                axios.defaults.headers.common[ "Authorization" ] = `Bearer ${localStorage.token}`;
            axios.get( CONSTANTES.URL_RUTA_SERVICIOS + "trabajador/cargar-datos-capacitacion-trabajador", {
                params: {
                    idPersona: me.idPersona,
                    idPersonaCapacitacion: me.datosParam.idPersonaCapacitacion
                }
            }).then(function (response) {
                    if (response) {
                        me.datosCapacitacion.idPersonaCapacitacion  = response.data[0].id_persona_capacitacion
                        me.datosCapacitacion.idCapacitacion         = response.data[0].idCapacitacion
                        me.datosCapacitacion.nombre                 = response.data[0].nombre
                        me.datosCapacitacion.descripcion            = response.data[0].descripcion
                        me.datosCapacitacion.nombre_tipo            = response.data[0].nombre_tipo
                        me.datosCapacitacion.urlPortada             = response.data[0].portada
                        me.datosCapacitacion.urlArchivo             = response.data[0].url
                        me.datosCapacitacion.proceso                = response.data[0].proceso
                        me.datosCapacitacion.documento              = response.data[0].documento

                        me.ListarArchivos();
                        me.ListarEvaluacionesCapacitacion();
                        //me.leerEstadosDeck();
                        me.cargarInfoCapacitacion = false;

                    }
            }).catch(function (error) {
                    me.$notify({
                        verticalAlign   : 'bottom',
                        horizontalAlign : 'right',
                        type            : 'danger',
                        icon            : 'emoji-frown',
                        message         : 'Error, algo salió mal.' + error
                    });
                    me.cargarInfoCapacitacion = true
            }).finally(() => {
                me.isLoading = false;
            });
        },
        CargarProcesoActualizado() {
            let me = this;
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;
            axios.get(CONSTANTES.URL_RUTA_SERVICIOS + "trabajador/cargar-datos-capacitacion-trabajador", {
                params: {
                    idPersona: me.idPersona,
                    idPersonaCapacitacion: me.datosCapacitacion.idPersonaCapacitacion,
                }
            }).then(function (response) {
                if (response) {
                    me.datosCapacitacion.proceso = response.data[0].proceso;
                }
            }).catch(function (error) {
                me.$notify({
                    verticalAlign: 'bottom',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Error, algo salió mal.'
                });
            });
        },
        ListarEvaluacionesCapacitacion() {
            let me = this;
            me.cargarEvaluacion = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "trabajador/listar-evaluacion-capacitacion", {
                        params: {
                            idCapacitacion: me.datosCapacitacion.idCapacitacion,
                        },
                    }
                )
                .then(function (response) {
                    me.listaEvaluaciones = response.data
                    me.filasTotalesEva = me.listaEvaluaciones.length
                    me.cargarEvaluacion = false
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                    me.cargarEvaluacion = true
                });
        },
        ListarArchivos() {
            let me = this;
            me.cargandoArchivos = true
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;
            axios.get( CONSTANTES.URL_RUTA_SERVICIOS + "trabajador/listar-archivos", {
                params: {
                    idCapacitacion: me.datosCapacitacion.idCapacitacion,
                    idCliente: me.idCliente,
                },
            }).then(function (response) {
                me.cargandoArchivos = false
                me.listaArchivos = response.data.filter(
                    (tipo) => tipo.idTipoArchivo == 1
                );
                me.filasTotalesArc = me.listaArchivos.length

            }).catch(function (error) {
                me.$notify({
                    verticalAlign: 'bottom',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Error, algo salió mal.'
                });
                me.cargandoArchivos = true
            });
        },
        ActualizarEstadoCapacitacion(estado) {
            let me = this
            axios.post( CONSTANTES.URL_RUTA_SERVICIOS + "trabajador/actualizar-capacitacion-proceso", {
                        id_persona_capacitacion: me.datosCapacitacion.idPersonaCapacitacion,
                        proceso: estado,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
            }).then(function (response) {
                    if (response) {
                        me.CargarProcesoActualizado();
                    }
            }).catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
            });
        },

        descargarArchivo(param) {
            let me = this
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios.get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "trabajador/descargar-archivo", {
                        params: {
                            ruta: param.item.rutaNube,
                            archivo: param.item.nombreArchivo,
                            idArchivo: param.item.idArchivo,
                        },
                    }
                )
                .then(function (response) {
                    window.open(response.data);
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        AbrirParam(param) {
            let me = this
            me.$router.push({
                name: "nueva evaluacion",
                params: {
                    idcaper: me.datosParam.idPersonaCapacitacion,
                    id: param.item.id_capacitacion_evaluacion,
                    nombre: param.item.nombre,
                },
            });
        },

        decryptData(encryptedData) {
            try{
                const SECRET_KEY    = process.env.VUE_APP_SECRET_KEY; 
                const bytes         = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
                const decrypt       = bytes.toString(CryptoJS.enc.Utf8);
                return decrypt;
            }catch(error){
                console.log(error);
            }
        },

    },
    mounted() {
        if (localStorage.usuario) {
            let me = this;
            const decrypt   = me.decryptData(localStorage.usuario);
            const lsUsuario = JSON.parse(decrypt); 
            me.idCliente    = lsUsuario.nIdCliente
            me.idPersona    = lsUsuario.idPersona
            me.datosParam.idPersonaCapacitacion = me.$route.params.id
            me.cargarDatosCapacitacionTrabajador();

        }
    }
};
</script>
