<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12">
                    <card header-classes="bg-transparent">
                        <template v-slot:header>
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h3 class="mb-0">Resportes </h3>
                                </b-col>
                                <b-col class="text-right">
                                    <span class="btn btn-sm btn-primary" v-b-modal.modal-registro-cliente>
                                        <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar Cliente
                                    </span>
                                </b-col>
                            </b-row>
                        </template>
                             <b-row class="text-center">
                                    <h3 class="text-muted">Lista de personas y su avance del curso, examenes culminados,</h3>
                                    <br>
                                       <h3 class="text-muted">   actividad de trabajadores etc en pdf y listar en table</h3>
                            </b-row>
                    </card>
                </b-col>
            </b-row>
        </div>
    </b-container>
    <!--End container-->
</div>
</template>

<script>
//import axios from "axios";
//import CONSTANTES from "@/Constantes.js";


export default {
    name: "plantilla",
    components: {

    },
    data() {
        return {

        };
    },
    methods: {

    },
    watch: {

    },
    mounted() {

    }
};
</script>
